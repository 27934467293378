import React, {Component} from 'react'
import { Breadcrumb, Segment, Header,Menu, Icon, Input} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import BranchesList from './list';
//import UserAdd from './add';


class Branches extends Component{
    state={
        search:""
    }
    filterSearch(entry){
        if(this.state.search!=""){
            if((entry.name).includes(this.state.search)){ return true }
            if(entry.address){if(entry.address.includes(this.state.search)){ return true }}
            if(entry.postcode){if(entry.postcode.includes(this.state.search)){ return true }}
            return false
        }else{
            return true
        }
    }
    render(){
        return (
            <div style={{marginRight:0,marginLeft:0}} >
                <Segment attached="bottom" style={{borderRadius:0,marginRight:0,marginLeft:0,marginBottom:0,borderLeft:0}} >
                    <Breadcrumb>
                        <Breadcrumb.Section>Infrastruktur</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active>Filialen</Breadcrumb.Section>
                    </Breadcrumb>
                    <Header as='h1' style={{border:0,marginTop:10}}>Filialen</Header>
                </Segment>
                <Menu borderless attached="bottom" style={{marginBottom:0}}>
                    <Input onChange={(e,{value})=>this.setState({search:value})} transparent iconPosition='left' icon="search" placeholder="Filiale suchen" />
                </Menu>
                <BranchesList search={this.filterSearch.bind(this)} />
            </div>
        )
    }
} 

export default Branches