import React, { Component } from 'react';
import { Breadcrumb, Loader } from 'semantic-ui-react';
import {withRouter} from 'react-router-dom'
import client from '../../../../shared/feathers';


class BranchCompact extends Component{
    state={
        loading: true,
        register: null,
        branch: null
    }
    async componentDidMount(){
        if(this.props.register){
            let register = await client.service("registers").get(this.props.register_id)
            this.setState({loading:false,register,branch: await client.service("branches").get(register.branch_id)})
        }else{
            this.setState({loading:false,branch: await client.service("branches").get(this.props.branch_id)})
        }
    }

    render(){
        return (
            <div>
                {this.state.loading && <Loader active inline='centered' size="mini" />}
                {!this.state.loading && <Breadcrumb size='mini'>
                    <Breadcrumb.Section link onClick={()=>this.props.history.push("/Infrastructure/Branches/"+this.state.branch.id)}><b>{this.state.branch.name}</b></Breadcrumb.Section>
                    {this.state.register != null && [
                        <Breadcrumb.Divider key="1" icon='right chevron' />,
                        <Breadcrumb.Section key="2" >{this.state.register.name}</Breadcrumb.Section>
                    ]}
                    
                </Breadcrumb>}
            </div>
        )
    }
} 

export default withRouter(BranchCompact)