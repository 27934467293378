import React, {Component} from 'react'
import { Breadcrumb, Segment, Header, Menu, Icon} from 'semantic-ui-react';
import { withRouter, Route, Link } from "react-router-dom";
import CustomerSlider from './Slider'
class Controlling extends Component{
    state={
        active:"customerdisplay"
    }
    render(){
        return (
            <div  >
                <Menu icon='labeled' secondary attached="bottom" style={{borderRadius:0,backgroundColor:"#fff",marginBottom:0}}>
                    <Menu.Item onClick={()=>this.props.history.push("/Controlling/Slider")} active><Icon name="tv"/>Kundendisplay</Menu.Item>
                    <Menu.Item onClick={()=>{}}><Icon name="bullhorn"/>Benachrichtigungen</Menu.Item>
                    <Menu.Item onClick={()=>{}}><Icon name="check square"/>Checklisten</Menu.Item>
                    <Menu.Item onClick={()=>{}}><Icon name="searchengin"/>Best Preis suche</Menu.Item>
                </Menu>
                <Route path={`${this.props.match.path}`} exact component={CustomerSlider} />
                <Route path={`${this.props.match.path}/Slider`} component={CustomerSlider} />
            </div>
        )
    }
} 

export default withRouter(Controlling)