
import React, {Component} from 'react'
import { Icon,Breadcrumb, Segment, Header,Dimmer,Loader, Form, Checkbox, Grid} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import UsersList from './list';
import UserAPIKeys from './ApiKeys';


class UserSingle extends Component{
    state={
        user:null,
        currentUser:null,
        loading:true,
        companies:[],
        saving:false,

    }
    
    async componentDidMount(){
        this.setState({currentUser:await client.service("user").find()})
        
        client.service("companies").watch().find().subscribe(companies=>{
            this.setState({companies:companies.data.map(entry=>{return {key:entry.id,value:entry.id,text:entry.name}})})
        })
        client.service("users").get(this.props.match.params.id).then(user=>this.setState({user,loading:false})).catch(()=>this.setState({loading:false,user:null}))
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.match.params.id!=this.props.match.params.id){
            this.setState({loading:true, user:null})
            this.updateService()
        }

    }
    updateService(){
        client.service("users").get(this.props.match.params.id).then(user=>this.setState({user,loading:false})).catch(()=>this.setState({loading:false,user:null}))
    }
    async updateField(field,value){
        await client.service("users").patch(this.props.match.params.id,{[field]: value})
        this.updateService()
    }
    async saveAccountDetails(){
        this.setState({saving:true})
        let changed = {}
        let {firstname,lastname,company_id,email,position,language,password} = this.state
        try{
            await client.service("users").patch(this.state.user.id,{firstname,lastname,company_id,email,position,language,password})
            this.setState({saving:false,password:undefined})
        }catch(e){
            this.setState({saving:false,password:undefined})
        }
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    render(){
        return (<div style={{marginRight:0,marginLeft:0}} >
                {(!this.state.loading && this.state.user != null) && <div>
                    <Segment clearing attached="bottom" style={{borderRadius:0,marginRight:0,marginLeft:0,marginBottom:0,borderLeft:0}} >
                        <Breadcrumb style={{width:"100%"}}>
                            <Breadcrumb.Section>Infrastruktur</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section >Benutzer</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active >#{this.state.user.id}</Breadcrumb.Section>
                        </Breadcrumb>
                        <Header as='h1' style={{border:0,marginTop:10}}>
                            {this.state.user.firstname} {this.state.user.lastname}
                            <Header.Subheader>{this.state.user.email}</Header.Subheader>
                        </Header>
                        <div >
                            <Checkbox onChange={this.updateField.bind(this,"active",! this.state.user.active)} checked={this.state.user.active == 1 ? true:false}  toggle label="Aktiviert" style={{marginRight:20}} />
                            { this.state.currentUser.isAdmin == 1 &&  <Checkbox onChange={this.updateField.bind(this,"isAdmin",! this.state.user.isAdmin)} toggle checked={this.state.user.isAdmin == 1 ? true:false}  label={<label style={{color:"#f00"}}><b>Admin</b></label>} />}
                        </div>
                    </Segment>
                    <Grid style={{padding:"1rem"}}>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                <Header attached="top"><Icon name="cogs" />Account Einstellungen</Header>
                                <Segment attached="bottom">
                                    <Form onSubmit={this.saveAccountDetails.bind(this)} loading={this.state.saving}>
                                        <Form.Group widths='equal'>
                                            <Form.Input required name="firstname" onChange={this.handleChange} defaultValue={this.state.user.firstname} fluid label='Vorname' placeholder='Vorname' />
                                            <Form.Input required name="lastname" onChange={this.handleChange} defaultValue={this.state.user.lastname} fluid label='Nachname' placeholder='Nachname' />
                                        </Form.Group>
                                        <Form.Input required name="email" onChange={this.handleChange} fluid defaultValue={this.state.user.email} label='E-Mail' placeholder='Bsp. Buchhaltung' />
                                        {this.state.currentUser.isAdmin==1 && (
                                            <Form.Select name="company_id" onChange={this.handleChange} required fluid label="Unternehmen" defaultValue={this.state.user.company_id} placeholder='Unternehmen auswählen' options={this.state.companies} />
                                        )}
                                        <Form.Input name="position" onChange={this.handleChange} fluid defaultValue={this.state.user.position} label='Position' placeholder='Bsp. Buchhaltung' />
                                        <Form.Select name="language" onChange={this.handleChange} fluid label="Sprache" value={this.state.user.language} placeholder='Sprache auswählen' options={[{key:"de",flag:"de",text:"Deutsch",value:"de"}]} />
                                        <Form.Input name="password" onChange={this.handleChange} fluid name="password" label='Neues Password' type="password" />
                                        <Form.Button content='Speichern' />
                                    </Form>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <UserAPIKeys user_id={this.state.user.id} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>}
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {(!this.state.loading && this.state.user==null) && <Header style={{width:"100%"}} icon>
                    <Icon name='search' />
                    Die gesuchte Ressource wurde nicht gefunden.
                </Header>}
            </div>
        )
    }
} 

export default UserSingle