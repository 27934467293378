import React, {Component} from 'react'
import { Icon,List, Loader, Menu } from 'semantic-ui-react';
import {  withRouter } from "react-router-dom";
import client from '../../../shared/feathers';


class InfrastructureList extends Component{
    state={
        branches:[],
        waregroups:[],
        discounts:[],
        users:[],
        modifier: [],
        loadingModifier: true,
        loadingWaregroups: true,
        loadingBranches: true,
        loadingDiscounts: true,
        loadingUsers: true
    }
    render(){
        return (      
            <Menu vertical attached="bottom" fluid style={{borderRight:0}} >
                <Menu.Item onClick={()=>this.props.history.push("/Infrastructure/Branches")}><span><Icon name="building" /> Filialen</span></Menu.Item>
                <Menu.Item onClick={()=>this.props.history.push("/Infrastructure/Users")}><span><Icon name="user md" /> Benutzer</span></Menu.Item>
                <Menu.Item onClick={()=>this.props.history.push("/Infrastructure/Operators")}><span><Icon name="users" /> Bediener</span></Menu.Item>
                <Menu.Item onClick={()=>this.props.history.push("/Infrastructure/Waregroups")}><span><Icon name="tags" /> Warengruppen</span></Menu.Item>
                <Menu.Item onClick={()=>this.props.history.push("/Infrastructure/Products")}><span><Icon name="coffee" /> Produkte</span></Menu.Item>
            </Menu>
        )
    }
} 

export default withRouter(InfrastructureList)