
import React, {Component} from 'react'
import { Icon,Breadcrumb, Segment, Header,Dimmer,Loader, Form, Checkbox, Grid, Table, TableCell} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import Currency from 'react-currency-formatter';
import OperatorSingleEdit from './edit';

class OperatorSingle extends Component{
    state={
        operator:null,
        loading:true,
        saving:false,
    }
    
    async componentDidMount(){
        client.service("operators").get(this.props.match.params.id).then(operator=>this.setState({operator,loading:false})).catch(()=>this.setState({loading:false,operator:null}))
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.match.params.id!=this.props.match.params.id){
            this.setState({loading:true, operator:null})
            this.updateService()
        }

    }
    updateService(){
        client.service("operators").get(this.props.match.params.id).then(operator=>this.setState({operator,loading:false})).catch(()=>this.setState({loading:false,operator:null}))
    }
    render(){
        return (<div style={{marginRight:0,marginLeft:0}} >
                {(!this.state.loading && this.state.operator != null) && <div>
                    <Segment clearing attached="bottom" style={{borderRadius:0,marginRight:0,marginLeft:0,marginBottom:0,borderLeft:0}} >
                        <Breadcrumb style={{width:"100%"}}>
                            <Breadcrumb.Section>Infrastruktur</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section >Produkte</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active >#{this.state.operator.realid}</Breadcrumb.Section>
                        </Breadcrumb>
                        <Header as='h1' style={{border:0,marginTop:10}}>{this.state.operator.name}</Header>
                    </Segment>
                    <Grid style={{padding:"1rem"}} columns="2">
                        <Grid.Column>
                            <Segment.Group>
                                <Header attached="top"><Icon name="address card" /> Adresse</Header>
                                <OperatorSingleEdit operator={this.state.operator} />
                            </Segment.Group>
                            
                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid>
                </div>}
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {(!this.state.loading && this.state.operator==null) && <Header style={{width:"100%"}} icon>
                    <Icon name='search' />
                    Die gesuchte Ressource wurde nicht gefunden.
                </Header>}
            </div>
        )
    }
} 

export default OperatorSingle