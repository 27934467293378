import React, { Component } from 'react';
import { Table, Statistic, Loader, Segment, Icon, Button } from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import Moment from 'react-moment';
import client from '../../../shared/feathers';
import BranchCompact from '../../Infrastructure/compact/Name/Branch';
import {collabsBranches} from './helper'

let RegisterBranches = {}

class AccountingRealtime extends Component{
    state={
        data: null,
        active:null,
        overall:null,
        open:false
    }
    componentDidMount(){
        this.setState({queryString:JSON.stringify(this.props.query)},this.update.bind(this))
    }
    componentDidUpdate(prevProps){
        if(this.state.queryString != JSON.stringify(this.props.query)){
            this.setState({queryString:JSON.stringify(this.props.query)},this.update.bind(this))
        }
    }
    update(){
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("reports_x_accounting_combined").watch().find({query: {$sort:{id:-1},...this.props.query }}).subscribe(async data=>this.setState({...await collabsBranches(data)}))
    }
    componentWillUnmount(){
        this.reactive.unsubscribe()
    }
    
    render(){
        return (
            <Segment.Group >
                <Segment attached="top" loading={!this.state.overall}>
                    {this.state.overall&&<Statistic.Group size="tiny"  widths='10'>
                        <Statistic><Statistic.Value><Currency quantity={this.state.overall.brutto} currency="EUR" /></Statistic.Value><Statistic.Label>Brutto</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value><Currency quantity={this.state.overall.netto} currency="EUR" /></Statistic.Value><Statistic.Label>Netto</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value><Currency quantity={this.state.overall.tax} currency="EUR" /></Statistic.Value><Statistic.Label>Steuern</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value>{this.state.overall.customers}</Statistic.Value><Statistic.Label>Kunden</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value>{this.state.overall.corrections}</Statistic.Value><Statistic.Label>Stornos</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value><Currency quantity={this.state.overall.corrections_value} currency="EUR" /></Statistic.Value><Statistic.Label>Storno Wert</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value><Currency quantity={this.state.overall.average_per_customer} currency="EUR" /></Statistic.Value><Statistic.Label>ø Rechnung</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value><Currency quantity={this.state.overall.average_per_table} currency="EUR" /></Statistic.Value><Statistic.Label>ø Inhaus/Rn</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value><Currency quantity={this.state.overall.average_off_table} currency="EUR" /></Statistic.Value><Statistic.Label>ø Außer Haus/Rn</Statistic.Label></Statistic>
                        <Statistic><Statistic.Value>{this.state.overall.value_off_table}</Statistic.Value><Statistic.Label>Außer Haus</Statistic.Label></Statistic>
                    </Statistic.Group>}
                </Segment>
                {this.state.open && <Button onClick={()=>this.setState({open:!this.state.open})} attached="bottom">Schließen</Button>}
                {!this.state.open && <Button onClick={()=>this.setState({open:!this.state.open})} attached="bottom">Mehr informationen</Button>}
                {this.state.open && <Segment key="table" placeholder={this.state.data==null} loading={this.state.data==null} {...this.props.segmentProps}>

                    {(this.state.data != null) &&
                        <Table {...this.props.tableProps}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="2">Filiale</Table.HeaderCell>
                                    <Table.HeaderCell>Brutto</Table.HeaderCell>
                                    <Table.HeaderCell>Netto</Table.HeaderCell>
                                    <Table.HeaderCell>Stornos</Table.HeaderCell>
                                    <Table.HeaderCell>ø Rechnung</Table.HeaderCell>
                                    <Table.HeaderCell>ø in Haus</Table.HeaderCell>
                                    <Table.HeaderCell>ø außer Haus</Table.HeaderCell>
                                    <Table.HeaderCell>Datum</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.data.data.map(entry => [
                                    (<Table.Row key={entry.id}>
                                        <Table.Cell collapsing><Button icon size="mini" onClick={()=>this.setState({active:entry.id})}><Icon name="plus" /></Button></Table.Cell>
                                        <Table.Cell><BranchCompact branch_id={entry.branch_id}/></Table.Cell>
                                        <Table.Cell><Currency quantity={entry.brutto} currency="EUR" /></Table.Cell>
                                        <Table.Cell><Currency quantity={entry.netto} currency="EUR" /></Table.Cell>
                                        <Table.Cell>{entry.corrections + entry.cancellations} (<Currency quantity={entry.corrections_value + entry.cancellation_value} currency="EUR" />)</Table.Cell>
                                        <Table.Cell><Currency quantity={entry.average_per_customer} currency="EUR" /></Table.Cell>
                                        <Table.Cell><Currency quantity={entry.value_on_table} currency="EUR" /></Table.Cell>
                                        <Table.Cell><Currency quantity={entry.value_off_table} currency="EUR" /></Table.Cell>
                                        <Table.Cell><Moment date={entry.day} format="DD.MM.YYYY" /></Table.Cell>
                                    </Table.Row>),
                                    <Table.Row key={entry.id+"_extra"} >
                                        <Table.Cell style={{padding:0,display:(this.state.active == entry.id) ?"table-cell":"none"}} colSpan="9">
                                            <Table compact="very" size="small" >
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell >Kasse</Table.HeaderCell>
                                                        <Table.HeaderCell>Brutto</Table.HeaderCell>
                                                        <Table.HeaderCell>Netto</Table.HeaderCell>
                                                        <Table.HeaderCell>Stornos</Table.HeaderCell>
                                                        <Table.HeaderCell>ø Rechnung</Table.HeaderCell>
                                                        <Table.HeaderCell>ø in Haus</Table.HeaderCell>
                                                        <Table.HeaderCell>ø außer Haus</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {entry.children.map(child => [
                                                        <Table.Row key={child.id}>
                                                            <Table.Cell><BranchCompact register register_id={child.register_id}/></Table.Cell>
                                                            <Table.Cell><Currency quantity={child.brutto} currency="EUR" /></Table.Cell>
                                                            <Table.Cell><Currency quantity={child.netto} currency="EUR" /></Table.Cell>
                                                            <Table.Cell>{child.corrections + child.cancellations} (<Currency quantity={child.corrections_value + child.cancellation_value} currency="EUR" />)</Table.Cell>
                                                            <Table.Cell><Currency quantity={child.average_per_customer} currency="EUR" /></Table.Cell>
                                                            <Table.Cell><Currency quantity={child.value_on_table} currency="EUR" /></Table.Cell>
                                                            <Table.Cell><Currency quantity={child.value_off_table} currency="EUR" /></Table.Cell>
                                                        </Table.Row>
                                                    ])}
                                                </Table.Body>
                                            </Table>
                                            
                                        </Table.Cell>
                                    </Table.Row>

                                ])}
                            </Table.Body>
                        </Table>
                    }
                </Segment>}
            </Segment.Group>
        )
    }
} 

export default AccountingRealtime