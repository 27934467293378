import React, {Component} from 'react'
import { Button, Segment, Header, Table, Checkbox, Flag,Icon} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import {  withRouter, Link } from "react-router-dom";


class UsersList extends Component{
    state={
        users:{total: 0, limit:1000, skip: 0,data:[]},
        loading: true,
        page: 1
    }
    async componentWillReceiveProps(){
        this.setState({loading:true,users:{total: 0, limit:1000, skip: 0,data:[]}})
        this.setState({loading:false,users:await client.service("users").find({query:{$limit:1000,$skip:this.state.page * this.state.users.limit - this.state.users.limit}})})
    }
    render(){
        return (
            <div>
                <Segment attached="bottom" placeholder={this.state.loading || this.state.users.data.length ==0} loading={this.state.loading} style={{padding:0, border:0, marginBottom:0}}>
                    {(!this.state.loading && this.state.users.data.length ==0) &&
                        <Header icon>
                            <Icon name='search' />
                            Es wurden Keine Benutzer gefunden.
                        </Header>
                    }
                    {(!this.state.loading && this.state.users.data.length >0) &&
                    <Table compact style={{borderRadius:0}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>ID</Table.HeaderCell>
                                <Table.HeaderCell>E-Mail</Table.HeaderCell>
                                <Table.HeaderCell>Vorname</Table.HeaderCell>
                                <Table.HeaderCell>Nachname</Table.HeaderCell>
                                <Table.HeaderCell>Position</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Sprache</Table.HeaderCell>
                                <Table.HeaderCell collapsing colSpan="2">Aktiv</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.users.data.filter(this.props.search).map(entry=>
                                <Table.Row key={entry.id}>
                                    <Table.Cell><Link to={"/Infrastructure/Users/"+entry.id}>{entry.id}</Link></Table.Cell>
                                    <Table.Cell><Link to={"/Infrastructure/Users/"+entry.id}>{entry.email}</Link></Table.Cell>
                                    <Table.Cell>{entry.firstname}</Table.Cell>
                                    <Table.Cell>{entry.lastname}</Table.Cell>
                                    <Table.Cell>{entry.position}</Table.Cell>
                                    <Table.Cell ><Flag name={entry.language} /></Table.Cell>
                                    <Table.Cell collapsing><Checkbox toggle checked={entry.active ==1 ? true:false} onChange={()=>client.service("users").patch(entry.id,{active:!entry.active})} /></Table.Cell>
                                    <Table.Cell collapsing><Button onClick={()=>client.service("users").remove(entry.id)} icon color="red" size="mini" ><Icon name="trash" /></Button></Table.Cell>
                                </Table.Row>    
                            )}
                        </Table.Body>
                    </Table>
                    }
                </Segment>
                
            </div>
        )
    }
} 

export default withRouter(UsersList)