import React, { Component } from 'react';
import { Table, Header, Statistic,Breadcrumb, Segment, Icon, Button,Pagination,Menu,Checkbox } from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import Moment from 'react-moment';
import client from '../../../shared/feathers';
import BranchCompact from '../../Infrastructure/compact/Name/Branch';
import {DatesRangeInput} from 'semantic-ui-calendar-react';
import OperatorCompact from "../../Infrastructure/compact/Name/Operator";
import MenuReports from '../Menu'
import moment from 'moment'

class Operators extends Component{
    state={ 
        data: {total:0,limit:1,data:[]},
        active:null, 
        page:1,
        date:"",
        query:{},
        loading:true,
        combine:false,
        renderAll:false
    }
    componentDidMount(){
        this.setState({queryString:JSON.stringify(this.props.query)},this.update.bind(this))
    }
    componentDidUpdate(prevProps, prevState){
        let fullQuery = {}
        let changed = false
        if(this.state.queryString != JSON.stringify(this.props.query)){
            this.setState({queryString:JSON.stringify(this.props.query)},this.update.bind(this))
        }
        if(JSON.stringify(this.state.query) != JSON.stringify(prevState.query) || prevState.combine != this.state.combine ){
            this.update()
        }
        if(prevState.date != this.state.date){
            if(this.state.date!=''){
                let dates = this.state.date.split(" - ")
                if(dates[1]!=""){
                    changed=true
                    fullQuery["$and"]=[
                        {day:{$lte:dates[1]}},
                        {day:{$gte:dates[0]}}
                    ]
                }else{
                    changed=true
                    fullQuery["day"]=dates[0]
                }
            }else{
                changed=true
                fullQuery = {}
            }
        }
        if(this.state.page != prevState.page){
            changed=true
            fullQuery["$skip"] = (this.state.page * this.state.data.limit)-this.state.data.limit
        }
        if(this.state.renderAll != prevState.renderAll ){
            changed=true
            if(this.state.renderAll){
                fullQuery["$limit"] = 50000
            }else{
                fullQuery["$limit"] = 10
            }
        }
        if(changed){this.setState({query:fullQuery})}
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("reports_z_operators")
        .watch()
        .find({query: {$sort:{id:-1},...this.state.query,...this.props.query }})
        .subscribe(async data=>this.setState({data}))
        this.setState({loading:false})
    }
    componentWillUnmount(){
        this.reactive.unsubscribe()
    }
    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    render(){
        return (
            
            <Segment.Group placeholder={this.state.data.data.length<1} loading={this.state.loading} {...this.props.segmentProps}>
                {this.props.headerSmall && 
                    <Header as='h5' attached='top'>{this.props.headerSmall}</Header>
                }
                {this.props.header && 
                    <Segment attached="top" style={{borderRadius:0}} >
                        
                        <Breadcrumb>
                            <Breadcrumb.Section>Berichte</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active>Finanzbericht</Breadcrumb.Section>
                        </Breadcrumb>
                        <Header as='h1' style={{border:0,marginTop:10}}>Finanzbericht</Header>
                    </Segment>
                }
                {this.props.header && 
                    <MenuReports 
                        after={
                        <Menu.Menu position='right'>
                            {(this.state.combine && this.state.date !="") && <Menu.Item><Checkbox checked={this.state.renderAll} label='Alle Auswerten' onChange={()=>this.setState({renderAll:!this.state.renderAll})} /></Menu.Item>}
                            <Menu.Item><Checkbox checked={this.state.combine} label='Auswerten' onChange={()=>this.setState({combine:!this.state.combine})} /></Menu.Item>
                            <Menu.Item>
                                <DatesRangeInput 
                                    placeholder="Von - Bis"
                                    style={{width:250}}
                                    dateFormat={"YYYY-MM-DD"}
                                    transparent 
                                    localization='de' 
                                    value={this.state.date}
                                    onChange={(event, {name, value})=>this.setState({date:value})}
                                    clearable 
                                    clearIcon={<Icon name="remove" color="red" />} name='fromto' />
                            </Menu.Item>
                        </Menu.Menu>
                        } 
                        menuPorps={{style:{marginBottom:0}}} />                    
                        
                }
                {(this.state.data != null) &&
                    <Table celled attached {...this.props.tableProps}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="2">Bediener</Table.HeaderCell>
                                <Table.HeaderCell>Brutto</Table.HeaderCell>
                                <Table.HeaderCell>Netto</Table.HeaderCell>
                                <Table.HeaderCell>Stornos</Table.HeaderCell>
                                <Table.HeaderCell>Kunden</Table.HeaderCell>
                                <Table.HeaderCell>Datum</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.data.data.map(entry => [
                                (<Table.Row key={entry.id}>
                                    <Table.Cell collapsing>{entry.operator_id}</Table.Cell>
                                    <Table.Cell><OperatorCompact operator_realid={entry.operator_id} /></Table.Cell>
                                    <Table.Cell><Currency quantity={entry.brutto} currency="EUR" /></Table.Cell>
                                    <Table.Cell><Currency quantity={entry.netto} currency="EUR" /></Table.Cell>
                                    <Table.Cell>{entry.corrections + entry.cancellations} (<Currency quantity={entry.corrections_value + entry.cancellation_value} currency="EUR" />)</Table.Cell>
                                    <Table.Cell >{entry.customers}</Table.Cell>
                                    <Table.Cell><Moment date={entry.day} format="DD.MM.YYYY" /></Table.Cell>
                                </Table.Row>),
                                <Table.Row key={entry.id+"_extra"} >
                                    <Table.Cell style={{padding:0,display:(this.state.active == entry.id) ?"table-cell":"none"}} colSpan="9">
                                        <Table compact="very" size="small" style={{borderRadius:0}}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell >Kasse</Table.HeaderCell>
                                                    <Table.HeaderCell>Brutto</Table.HeaderCell>
                                                    <Table.HeaderCell>Netto</Table.HeaderCell>
                                                    <Table.HeaderCell>Stornos</Table.HeaderCell>
                                                    <Table.HeaderCell>ø Rechnung</Table.HeaderCell>
                                                    <Table.HeaderCell>ø in Haus</Table.HeaderCell>
                                                    <Table.HeaderCell>ø außer Haus</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                        
                                    </Table.Cell>
                                </Table.Row>

                            ])}
                        </Table.Body>
                    </Table>
                }
                {this.props.pagination && 
                    <Pagination 
                    onPageChange={this.changePage.bind(this)}
                    attached="bottom" 
                    boundaryRange={2} 
                    siblingRange={2} 
                    fluid 
                    defaultActivePage={this.state.page} 
                    totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />
                }
                {this.props.groupChildren}
            </Segment.Group>
        )
    }
} 

export default Operators