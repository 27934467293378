import React, {Component} from 'react'
import { Menu,Icon} from 'semantic-ui-react'
import { withRouter, Route, Link } from "react-router-dom";
import client from '../../shared/feathers'
import Companies from './Companies';
import CompanySingle from './Companies/Single';

class Admin extends Component{
    render(){
        return (
            <div>
                <Menu icon='labeled' secondary attached="bottom" style={{borderRadius:0,backgroundColor:"#fff",marginBottom:0}}>
                    <Menu.Item onClick={()=>{this.props.history.push("/Admin/Companies")}} active>Unternehmen</Menu.Item>
                </Menu>
                <div style={{overflowX:"hidden",overflowY:"auto", position:"absolute",top:83,bottom:0,width:"100%"}}>
                    <Route path={`${this.props.match.path}`} exact component={Companies} />
                    <Route path={`${this.props.match.path}/Companies`} exact component={Companies} />
                    <Route path={`${this.props.match.path}/Companies/:id`} component={CompanySingle} />
                </div>
            </div>
        )
    }
} 

export default withRouter(Admin)