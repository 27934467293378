import React, {Component} from 'react'
import { Header,Segment, Grid, Menu, Icon, Input,Button, Label, Loader } from 'semantic-ui-react';
import client from '../../shared/feathers';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import GetName from './GetName';
import Moment from 'react-moment';


class Room extends Component{
    state={
        loading: true,
        name: "",
        notFound:false,
        user: null,
        messages:[],
        chat: {},
        extraEntrys:[],
        message:"",
        sending:false,
        total:1,
        page:0,
        finish:false
    }
    async componentDidMount(){
        let user=await client.service("user").find()
        this.setState({user},()=>{
            client.service("messages")
            .on('created',this.addSingle.bind(this));
            this.update()
            this.updateRead()
        })
    }
    async addSingle(message){
        await this.updateRead()
        this.setState({messages:[...this.state.messages,message]})
    }
    async update(){
        try{
            let room = await client.service("messages_rooms").get( this.props.match.params.id)
            this.updateRoom(room)
            this.setState({loading:false, notFound: false})
        }catch(e){this.setState({loading:false, notFound: true})}
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
        if(this.reactiveMessages){this.reactiveMessages.unsubscribe()}
    }
    async updateRoom(data){
        await this.updateRead()
        this.setState({chat:data,loading:false})
    }
    async updateRead(){
        let user=this.state.user
        let read = await client.service("messages_rooms_read").find({query:{
            room_id:this.props.match.params.id,
            user_id:user.id,
            user_type:"user",
        }})
        if(read.total>0){
            await client.service("messages_rooms_read").patch(read.data[0].id,{
                room_id:this.props.match.params.id,
                user_id:user.id,
                user_type:"user",
                updated_at:moment()
            })
        }else{
            await client.service("messages_rooms_read").create({
                room_id:this.props.match.params.id,
                user_id:user.id,
                user_type:"user",
                created_at:moment(),updated_at:moment()
            })
        }
    }
    async sendMessage(){
        this.setState({sending:true})
        await client.service("messages").create({
            text:this.state.message,
            from:this.state.user.id,
            from_type:"user",
            messages_room_id: this.props.match.params.id,
            updated_at:moment(),
            created_at:moment()
        })

        await client.service("messages_rooms").patch(this.props.match.params.id,{
            updated_at:moment()
        })
        this.setState({sending:false, message:""})
    }    
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    handleKeyPress = (event) => {
        if(event.key == 'Enter'){
            this.sendMessage()
        }
    }
    async loadMore(page){
        if(this.state.total >= page*10){
            let messages = await client.service("messages").find({
                query:{
                    $sort:{id:-1},messages_room_id:this.props.match.params.id,
                    $limit:10,
                    $skip:page*10
                }
            })
            this.setState({page,total:messages.total,messages:[...messages.data.reverse(),...this.state.messages]})
        }
    }
    render(){
        if(this.state.loading ){
            return <Segment basic><Segment loading placeholder></Segment></Segment>
        }
        if(this.state.notFound){
            return <Segment basic ><Segment placeholder style={{border:0,backgroundColor:"#f0f2f5"}}><Header icon><Icon name='comments outline' /> Chatraum wurde nicht gefunden</Header> </Segment></Segment>
        }
        console.log(this.state.total,this.state.page, this.state.page*10)
        return (
            <div style={{position:"absolute",top:15,bottom:0,left:0,overflow:"auto",width:"99%",paddingTop:50,paddingBottom:90}} ref={(el) => { this.messagesEnd = el; }}>
                <Header attached="bottom" style={{position:"fixed",top:44,width:"81.60%",zIndex:10}}>
                    {this.state.chat.private?<GetName from={this.state.chat.from} to={this.state.chat.to} from_type={this.state.chat.from_type} to_type={this.state.chat.to_type} />:this.state.chat.name}
                </Header>

                <div style={{position:"fixed",width:"81.60%",paddingLeft:15,paddingRight:15,bottom:15,zIndex:10}}>
                    <Input 
                        loading={this.state.sending} 
                        onKeyPress={this.handleKeyPress}  
                        value={this.state.message}
                        onChange={this.handleChange.bind(this)} 
                        name="message" 
                        placeholder="Nachricht" 
                        attached="bottom" floated="left" fluid  
                        action={{onClick:this.sendMessage.bind(this),content:'Senden'}}
                    />
                </div>
                <InfiniteScroll
                    style={{paddingLeft:18}}
                    useWindow={false}
                    pageStart={-1} isReverse
                    loadMore={this.loadMore.bind(this)}
                    hasMore={(this.state.total >= this.state.page*10)}
                    loader={<div  key="0"  style={{textAlign:"center"}}><Loader key="0" active inline/></div>}
                >
                    <Grid key="container">
                        {this.state.messages.map(el=>
                            <Grid.Row key={el.id}><Grid.Column>
                                {(this.state.user.id == el.from)&&el.from_type=="user" && 
                                <Segment style={{maxWidth:"50%",minWidth:"33%",paddingTop:20,paddingBottom:13}} floated={"right"} padded>
                                    <Label color="grey"  attached="top">
                                        <b>{this.state.user.firstname} {this.state.user.lastname}</b>
                                        <small style={{float:"right"}}><Moment format="HH:mm DD.MM.YYYY">{el.created_at}</Moment></small>
                                    </Label><br/>
                                    {el.text}
                                </Segment>}
                                {!((this.state.user.id == el.from)&&el.from_type=="user") && 
                                <Segment style={{maxWidth:"50%",minWidth:"33%",paddingTop:20,paddingBottom:13}} floated={"left"} padded>
                                    <Label color="blue" attached="top">
                                        <b><GetName from={el.from} from_type={el.from_type} /></b>
                                        <small style={{float:"right"}}><Moment format="HH:mm DD.MM.YYYY">{el.created_at}</Moment></small>
                                    </Label><br/>
                                    {el.text}
                                </Segment>}
                            </Grid.Column></Grid.Row>
                        )}
                    </Grid>
                </InfiniteScroll>
            </div>
        )
    }
} 

export default Room