import React, {Component} from 'react'
import { Button, Segment, Header, Table, Checkbox, Flag,Icon} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import {  withRouter, Link } from "react-router-dom";


class BranchesList extends Component{
    state={
        branches:{total: 0, limit:1000, skip: 0,data:[]},
        loading: true,
        page: 1,
        search: ""
    }
    async componentDidMount(){
        await this.update()
    }
    async componentWillUpdate(prevState){
        if(prevState.search!=this.state.search){
            this.setState({search:prevState.search})
            await this.update()
        }
    }
    async activate(id,value){
        await client.service("branches").patch(id,{active:!value})
        await this.update()
    }
    async update(){
        this.setState({loading:true,branches:{total: 0, limit:1000, skip: 0,data:[]}})
        this.setState({loading:false,branches:await client.service("branches").find({query:{$limit:1000,$skip:this.state.page * this.state.branches.limit - this.state.branches.limit}})})
    }
    render(){
        return (
            <div>
                <Segment attached="bottom" placeholder={this.state.loading || this.state.branches.data.length ==0} loading={this.state.loading} style={{padding:0, border:0, marginBottom:0}}>
                    {(!this.state.loading && this.state.branches.data.length ==0) &&
                        <Header icon>
                            <Icon name='search' />
                            Es wurden Keine Benutzer gefunden.
                        </Header>
                    }
                    {(!this.state.loading && this.state.branches.data.length >0) &&
                    <Table compact style={{borderRadius:0}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>ID</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Adresse</Table.HeaderCell>
                                <Table.HeaderCell>Stadt</Table.HeaderCell>
                                <Table.HeaderCell>Telefon</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Aktiv</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.branches.data.filter(this.props.search).map(entry=>
                                <Table.Row key={entry.id}>
                                    <Table.Cell><Link to={"/Infrastructure/Branches/"+entry.id}>{entry.realid}</Link></Table.Cell>
                                    <Table.Cell><Link to={"/Infrastructure/Branches/"+entry.id}>{entry.name}</Link></Table.Cell>
                                    <Table.Cell>{entry.address} {entry.address_extra}</Table.Cell>
                                    <Table.Cell>{entry.city} {entry.postcode}</Table.Cell>
                                    <Table.Cell>{entry.phone}</Table.Cell>
                                    <Table.Cell collapsing><Checkbox toggle checked={entry.active ==1 ? true:false} onChange={this.activate.bind(this,entry.id,entry.active)} /></Table.Cell>
                                </Table.Row>    
                            )}
                        </Table.Body>
                    </Table>
                    }
                </Segment>
                
            </div>
        )
    }
} 

export default withRouter(BranchesList)