import React, {Component} from 'react'
import { Segment, Breadcrumb, Header,Menu,Grid, Button, } from 'semantic-ui-react';
import Accounting from './Accounting';
import AccountingRealtime from './Accounting/realtime';
import Receipts from './Receipts';

import moment from 'moment'
import MenuReports from './Menu'

class Dashboard extends Component{
    state={
        accounting: moment().subtract(1,"days")
    }
    render(){
        return (
            <div>
                <Segment.Group attached="bottom">
                    <Segment attached="top" style={{borderRadius:0}} >
                        <Breadcrumb>
                            <Breadcrumb.Section>Berichte</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active>Dashboard</Breadcrumb.Section>
                        </Breadcrumb>
                        <Header as='h1' style={{border:0,marginTop:10}}>Dashboard</Header>
                    </Segment>
                    <MenuReports/>
                </Segment.Group>
                <Segment basic >
                    <Grid columns="2">
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <AccountingRealtime  query={{$limit:1000,day:moment().format("YYYY-MM-DD")}} segmentProps={{attached:true, style:{padding:0}}} tableProps={{style:{borderRadius:0,border:0}, size:"small",celled:true}}  />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                    
                                    <Accounting 
                                    groupChildren={<Button attached="bottom" fluid   
                                    onClick={()=>this.props.history.push("/Reports/Accounting")}>Mehr anzeigen</Button>}
                                    headerSmall="Finanzbericht - Gestern" 
                                    query={{$limit:1000,day:this.state.accounting.format("YYYY-MM-DD")}} 
                                    segmentProps={{attached:true, style:{padding:0}}} 
                                    tableProps={{style:{borderRadius:0,border:0}, size:"small",celled:true}}  />
                                    
                            </Grid.Column>
                            <Grid.Column>
                                    <Receipts 
                                        segmentProps={{attached:true, style:{padding:0}}}
                                        tableProps={{style:{borderRadius:0,border:0},compact:"very", size:"small"}} 
                                        headerSmall="Rechnungen - Echtzeit"
                                        groupChildren={<Button attached="bottom" fluid onClick={()=>this.props.history.push("/Reports/Receipts")}>Mehr anzeigen</Button>}
                                    />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

            </div>
        )
    }
} 

export default Dashboard