import React, {Component} from 'react'
import { Breadcrumb, Segment, Header,Menu, Icon, Input} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import UsersList from './list';
import UserAdd from './add';


class Users extends Component{
    state={
        users:[],
        loadingUsers: true,
        result: {total:0,limit:10},
        page: 1,
        search:""
    }
    componentDidMount(){
        this.reactiveUsers = client.service("users").watch().find().subscribe(({data})=>this.setState({users:data,loadingUsers:false}))
    }
    componentWillUnmount(){
        this.reactiveUsers.unsubscribe()
    }
    filterSearch(entry){
        if(this.state.search!=""){
            if((entry.firstname+" "+entry.lastname).includes(this.state.search)){ return true }
            if(entry.email.includes(this.state.search)){ return true }
            if(entry.position){if(entry.position.includes(this.state.search)){ return true }}
            return false
        }else{
            return true
        }
    }
    render(){
        return (
            <div style={{marginRight:0,marginLeft:0}} >
                <Segment attached="bottom" style={{borderRadius:0,marginRight:0,marginLeft:0,marginBottom:0,borderLeft:0}} >
                    <Breadcrumb>
                        <Breadcrumb.Section>Infrastruktur</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active>Benutzer</Breadcrumb.Section>
                    </Breadcrumb>
                    <Header as='h1' style={{border:0,marginTop:10}}>Benutzer</Header>
                </Segment>
                <Menu borderless attached="bottom" style={{marginBottom:0}}>
                    <UserAdd />
                    <Menu.Menu position='right'>
                        <Input onChange={(e,{value})=>this.setState({search:value})} transparent iconPosition='left' icon="search" placeholder="Benutzer suchen" />
                    </Menu.Menu>
                </Menu>
                <UsersList search={this.filterSearch.bind(this)} />
            </div>
        )
    }
} 

export default Users