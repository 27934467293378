
import React, {Component} from 'react'
import { Form, Segment } from 'semantic-ui-react';
import client from '../../../shared/feathers';

class OperatorSingleEdit extends Component{
    state={}
    
    async componentDidMount(){
        this.setState({...this.props.operator })
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.operator.id!=this.props.operator.id){
            this.setState({...this.props.operator })
        }
    }
    
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    async saveAccountDetails(){
        this.setState({saving:true})
        let {address,address_extra,postcode,city,phone,description} = this.state
        try{
            await client.service("operators").patch(this.state.id,{address,address_extra,postcode,city,phone,description})
            this.setState({saving:false})
        }catch(e){
            console.log(e)
            this.setState({saving:false})
        }
    }
    render(){
        
        return (
            <Segment attached>
                {this.state.id && <Form loading={this.state.saving} onSubmit={this.saveAccountDetails.bind(this)}>
                    <Form.Input name="address" onChange={this.handleChange} fluid defaultValue={this.state.address} label='Adresse' />
                    <Form.Input name="address_extra" onChange={this.handleChange} fluid defaultValue={this.state.address_extra}  />
                    <Form.Group widths='equal'>
                        <Form.Input width="3" type="number" name="postcode" onChange={this.handleChange} defaultValue={this.state.postcode} fluid label='PLZ'  />
                        <Form.Input name="city" onChange={this.handleChange} defaultValue={this.state.city} fluid label='Stadt'  />
                    </Form.Group>
                    <Form.Input name="phone" onChange={this.handleChange} fluid defaultValue={this.state.phone} label='Telefon'  />
                    <Form.TextArea name="description" onChange={this.handleChange} fluid defaultValue={this.state.description} label='Notiz'  />
                    
                    <Form.Button primary content='Speichern' />
                    
                </Form>}
            </Segment>
        )
    }
} 

export default OperatorSingleEdit