import React, {Component} from 'react'
import { Segment, Breadcrumb, Header,Container,Grid } from 'semantic-ui-react';
import InfrastructureList from './List';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import Stats from './List/Stats';
import Users from './Users';
import client from '../../shared/feathers';
import UserSingle from './Users/single';
import Branches from './Branches';
import BranchSingle from './Branches/single';
import Products from './Products';
import ProductSingle from './Products/single';
import Waregroups from './Waregroups';
import WaregroupSingle from './Waregroups/single';
import Operators from './Operators';
import OperatorSingle from './Operators/single';

class Infrastructure extends Component{
    state={
        company:{name:""}
    }
    async componentDidMount(){
        let user = await client.service("user").find()
        this.setState({ company:await client.service("companies").get(user.company_id)})
    }
    render(){
        return (            
            <Grid columns='equal' >
                <Grid.Column width="3" style={{paddingRight:0}} >
                    <Segment.Group attached="bottom" style={{borderRadius:0, height:"calc(100vh - 43px)", overflow:"auto",backgroundColor:"#fff"}} >
                        <Segment>
                            <Breadcrumb>
                                <Breadcrumb.Section>Infrastruktur</Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>Liste</Breadcrumb.Section>
                            </Breadcrumb>
                            <Header as='h1' style={{border:0,marginTop:10}} onClick={()=>this.props.history.push("/Infrastructure")}>{this.state.company.name}</Header>
                        </Segment>
                        <InfrastructureList />
                    </Segment.Group>
                </Grid.Column>
                
                <Grid.Column style={{height:"calc(100vh - 43px)", paddingLeft:0,overflow: "auto",top:"1rem",paddingTop: 0,paddingBottom: 0}} >
                    <Route path={`${this.props.match.path}`} exact component={Stats} />
                    <Route path={`${this.props.match.path}/Users`} exact component={(Users)} />
                    <Route path={`${this.props.match.path}/Users/:id`} exact component={UserSingle} />
                    <Route path={`${this.props.match.path}/Branches`} exact component={(Branches)} />
                    <Route path={`${this.props.match.path}/Branches/:id`} exact component={BranchSingle} />
                    <Route path={`${this.props.match.path}/Products`} exact component={(Products)} />
                    <Route path={`${this.props.match.path}/Products/:id`} exact component={ProductSingle} />
                    <Route path={`${this.props.match.path}/Waregroups`} exact component={(Waregroups)} />
                    <Route path={`${this.props.match.path}/Waregroups/:id`} exact component={WaregroupSingle} />
                    <Route path={`${this.props.match.path}/Operators`} exact component={(Operators)} />
                    <Route path={`${this.props.match.path}/Operators/:id`} exact component={OperatorSingle} />
                </Grid.Column>
            </Grid>
        )
    }
} 

export default withRouter(Infrastructure)
