import React, {Component} from 'react'
import { Modal,Button,Icon,Header, Table} from 'semantic-ui-react';


class RegistersMoreInformations extends Component{
    render(){
        if(!this.props.data.extra){return null}
        return (
            <Modal trigger={<Button >Mehr Informationen</Button>}>
                <Modal.Header>
                    <Header>
                        #{this.props.data.realid} {this.props.data.name}
                        <Header.Subheader>S/N: {this.props.data.serial}</Header.Subheader>
                    </Header>
                </Modal.Header>
                <Modal.Content  scrolling>
                    <Table definition>
                        <Table.Body>
                            {Object.keys(this.props.data.extra).map(key=>
                                <Table.Row key={key}>
                                    <Table.Cell>{key}</Table.Cell>
                                    <Table.Cell>{this.props.data.extra[key]}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>    
                </Modal.Content>
            </Modal>
        )
    }
} 

export default RegistersMoreInformations