import React, {Component} from 'react'
import { Button, Segment, Header, Table, Checkbox, Flag,Icon, Pagination, Menu, Input} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import {  withRouter, Link } from "react-router-dom";
import Currency from 'react-currency-formatter';
import TaxCompact from '../compact/Name/Tax';


class OperatorsList extends Component{
    state={
        operators:{total: 0, limit:20, skip: 0,data:[]},
        loading: true,
        page: 1,
        search:""
    }
    async componentWillMount(){
        this.setState({loading:true,operators:{total: 0, limit:20, skip: 0,data:[]}})
        await this.update()
    }
    async componentDidUpdate(prevProps,prevState){
        if(this.state.search!=prevState.search || JSON.stringify(this.props.query) !=JSON.stringify(this.props.query) ){
            this.setState({loading:true})
            await this.update()
        }
    }
    async update(){
        this.setState({loading:false,operators:await client.service("operators").find({query:{name:{$like:`%${this.state.search}%`},$sort:{realid:1},$limit:20,$skip:this.state.page * this.state.operators.limit - this.state.operators.limit, ...this.props.query}})})
    }
    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    render(){
        return (
            <div>
                {this.props.searchOn && <Menu borderless attached="bottom" style={{marginBottom:0,marginTop:"-1px"}}>
                    <Input onChange={(e,{value})=>this.setState({search:value})} transparent iconPosition='left' icon="search" placeholder="Bediener suchen" />
                </Menu>}
                <Segment attached="bottom" placeholder={this.state.loading || this.state.operators.data.length ==0} loading={this.state.loading} style={{padding:0, border:0, marginBottom:0}} {...this.props.segmentProps} >
                    {(!this.state.loading && this.state.operators.data.length ==0) &&
                        <Header icon>
                            <Icon name='search' />
                            Es wurden Keine Bediener gefunden.
                        </Header>
                    }
                    {(!this.state.loading && this.state.operators.data.length >0) &&
                    <Table compact style={{borderRadius:0}} size="small" {...this.props.tableProps}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>ID</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Notiz</Table.HeaderCell>
                                <Table.HeaderCell>Adresse</Table.HeaderCell>
                                <Table.HeaderCell>Stadt</Table.HeaderCell>
                                <Table.HeaderCell>Telefon</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.operators.data.map(entry=>
                                <Table.Row  key={entry.id}>
                                    <Table.Cell collapsing><Link to={"/Infrastructure/Operators/"+entry.id}>{entry.realid}</Link></Table.Cell>
                                    <Table.Cell selectable><Link to={"/Infrastructure/Operators/"+entry.id}>{entry.name}</Link></Table.Cell>
                                    <Table.Cell>{entry.description}</Table.Cell>
                                    <Table.Cell>{entry.address} {entry.address_extra}</Table.Cell>
                                    <Table.Cell>{entry.city} {entry.postcode}</Table.Cell>
                                    <Table.Cell>{entry.phone}</Table.Cell>
                                </Table.Row>    
                            )}
                        </Table.Body>
                    </Table>
                    }
                </Segment>
                <Pagination onPageChange={this.changePage.bind(this)} attached="bottom" boundaryRange={2} siblingRange={2} fluid defaultActivePage={this.state.page} totalPages={Math.ceil(this.state.operators.total / this.state.operators.limit)} />
            </div>
        )
    }
} 

export default withRouter(OperatorsList)