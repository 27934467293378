import React, {Component} from 'react'
import { Sidebar, List,Icon, Form, Select, Segment, Button } from 'semantic-ui-react'
import {  withRouter, Link } from "react-router-dom";
import client from '../../shared/feathers'

//()=>this.props.history.push("/Accounting")

class User extends Component{
    state = {
        user:{firstname:"Unknown",lastname:"",id:0,company_id:0},
        settings:false,
        activeTenant: 0,
        companies:[],
        tenants:[]
    }
    async componentDidMount(){
        let user = await client.service("user").find()
        let activeTenant = (await client.service("tenants").find({query:{active:true, company_id:user.company_id}})).data
        if(activeTenant.length>0){this.setState({activeTenant:activeTenant[0].id})}
        this.setState({user},this.updateTenants.bind(this))
        this.users = client.service("users").watch().get(user.id).subscribe(user=>this.setState({user}))
        this.companies = client.service("companies").watch().find().subscribe(companies=>{
            this.setState({companies:companies.data.map(entry=>{return {key:entry.id,value:entry.id,text:entry.name}})})
        })
    }
    updateTenants(){
        if(this.tentants){this.tentants.unsubscribe()}
        this.tenants = client.service("tenants").watch().find({query:{company_id:this.state.user.company_id}}).subscribe(tenants=>{
            this.setState({tenants:tenants.data.map(entry=>{return {key:entry.id,value:entry.id,text:entry.name}})})
        })
    }
    componentWillUnmount(){
        this.users.unsubscribe()
        this.companies.unsubscribe()
        this.tenants.unsubscribe()
    }
    async changeCompany(e,{name,value}){
        await client.service("users").patch(this.state.user.id,{company_id:value})
    }
    async changeTenant(e,{name,value}){
        for (const elm of this.state.tenants) {
            await client.service("tenants").patch(elm.value,{active:false})
        }
        await client.service("tenants").patch(value,{active:true})
        this.setState({activeTenant:value})
    }
    render(){
        return (
            <Sidebar
                style={{top:43,backgroundColor:"#fff",zIndex:10000}}
                animation='overlay'
                icon='labeled'
                direction="right"
                visible={this.props.active}
                width='wide'
            >
                <List size="large" style={{paddingLeft:20,paddingTop:20,paddingBottom:5}}>
                    <List.Item>
                        <List.Content floated="left"><Icon circular inverted color="grey" name='user' /></List.Content>
                        <List.Content>
                            <List.Header >{this.state.user.firstname} {this.state.user.lastname}</List.Header>
                            <List.Description>
                                <Link to={"/Infrastructure/Users/"+this.state.user.id}>Profile & Account</Link>
                            </List.Description>
                        </List.Content>
                    </List.Item>
                </List>
                <Segment style={{backgroundColor:"#f9f9f9"}} attached="top">
                    <Form >
                        {this.state.user.isAdmin==1 && (
                            <Form.Field>
                                <label>Unternehmen</label>
                                <Select value={this.state.user.company_id} onChange={this.changeCompany.bind(this)} placeholder='Unternehmen auswählen' options={this.state.companies} />
                            </Form.Field>
                        )}
                        {this.state.tenants.length>1 && (
                            <Form.Field>
                                <label>Datenbank</label>
                                <Select value={this.state.activeTenant} onChange={this.changeTenant.bind(this)} placeholder='Datenbank auswählen' options={this.state.tenants} />
                            </Form.Field>
                        )}
                        <Form.Field>
                            <label>Sprache</label>
                            <Select value={this.state.user.language} placeholder='Sprache auswählen' options={[{key:"de",flag:"de",text:"Deutsch",value:"de"}]} />
                        </Form.Field>
                    </Form>
                </Segment>
                <Segment  style={{backgroundColor:"#f9f9f9",position:"fixed",top:"auto",bottom:33,borderRadius:0,right:0, width:350,margin:0}}>
                    <Button  fluid onClick={()=>client.logout()} style={{backgroundColor:"#fff",border:"#d9d9d9 1px solid"}}>
                        <Icon name='shutdown' />
                        Abmelden
                    </Button>
                </Segment>
            </Sidebar>
        )
    }
} 

export default withRouter(User)