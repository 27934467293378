import React, {Component} from 'react'
import { Modal, Header, Button, Form} from 'semantic-ui-react';
import { withRouter, Route, Link } from "react-router-dom";
import client from '../../shared/feathers';
import moment from "moment"

class AddGroup extends Component{
    state={
        open:false,
        name:"",
        sort:0
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    close(){
        this.setState({name:"",sort:0,open:false})
    }
    async save(){
        const {name,sort} = this.state
        await client.service("messages_rooms").create({name,sort,created_at:moment(),updated_at:moment()})
        this.close()
    }
    render(){
        return (
            <Modal as={Form} open={this.state.open} onOpen={()=>this.setState({open:true})} onClose={this.close.bind(this)} size="mini" trigger={this.props.trigger}>
                
                <Modal.Content  >
                    <Form.Group widths="equal">
                        <Form.Input required onChange={this.handleChange.bind(this)} name="name" value={this.state.name} label="Gruppen-Name" />
                        <Form.Input onChange={this.handleChange.bind(this)} name="sort" type="number" value={this.state.sort} width="5" label="Sortierung" />
                    </Form.Group>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={this.close.bind(this)} as="a" size="small" negative>Schließen</Button>
                    <Button onClick={this.save.bind(this)} size="small" positive icon='checkmark' labelPosition='right' content='Erstellen' />
                </Modal.Actions>
            </Modal>
        )
    }
} 

export default withRouter(AddGroup)