import React, { Component } from 'react';
import { Breadcrumb, Loader } from 'semantic-ui-react';
import {withRouter} from 'react-router-dom'
import client from '../../../../shared/feathers';


class OperatorCompact extends Component{
    state={
        loading: true,
        register: null,
        operator: null
    }
    async componentDidMount(){
        if(this.props.operator_realid){
            this.setState({loading:false,operator: await client.service("realid-operators").get(this.props.operator_realid)})
        }else{
            this.setState({loading:false,operator: await client.service("operators").get(this.props.operator_id)})
        }
    }

    render(){
        return (
            <div>
                {this.state.loading && <Loader active inline='centered' size="mini" />}
                {!this.state.loading && <Breadcrumb size='mini'>
                    <Breadcrumb.Section link onClick={()=>this.props.history.push("/Infrastructure/Operators/"+this.state.operator.id)}>{this.state.operator.name}</Breadcrumb.Section>
                </Breadcrumb>}
            </div>
        )
    }
} 

export default withRouter(OperatorCompact)