import React, {Component} from 'react'
import {  Form, Grid, Header, Message, List, Segment } from 'semantic-ui-react'
import client from '../shared/feathers'

class Login extends Component{

    state = {
        err:null,
        loading:false
    }
    
    sendLogin(){
        this.setState({loading:true})
        const { email, password } = this.state;

        client.authenticate({
          strategy: 'local',
          email, password
        }).catch(err => this.setState({ err,loading:false }));

    }
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    render(){
        return (
            <div className="animated fadeIn" style={{ position:"fixed", top:0, left:0, width:"100vw", height:"100vh", backgroundImage: "url(/bg.jpg)", backgroundSize: "cover" }}>
                <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Form size='large' onSubmit={this.sendLogin.bind(this)}>
                            <Header as="h2" style={{color:"#fff"}}><font color="#f00">K</font>CONTROL</Header>
                            <Segment loading={this.props.loading || this.state.loading}>
                                <Form.Input name="email" fluid icon='user' iconPosition='left' placeholder='Email'  onChange={this.handleChange} />
                                <Form.Input name="password" fluid icon='lock' iconPosition='left' placeholder='Passwort' type='password' onChange={this.handleChange} />
                                <Form.Button fluid primary >Anmelden</Form.Button>
                                
                            </Segment>
                        </Form>
                        { this.state.err != null && <Message style={{textAlign:"left"}} error header={this.state.err.name} content={this.state.err.message} /> }
                        <div style={{marginTop:20}}>
                            <List bulleted horizontal>
                                <List.Item as='a' style={{color:"#fff"}}>Terms and Conditions</List.Item>
                                <List.Item as='a' style={{color:"#fff"}}>Support</List.Item>
                            </List>
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
} 

export default Login