import React, {Component} from 'react'
import {Segment, Breadcrumb, Header,Menu,Icon, Button} from 'semantic-ui-react'
import { withRouter, Route, Link } from "react-router-dom";

class CustomerSliderLayer extends Component{

    render(){
        
        return (
            <div>
                
                LOL
                
            </div>
        )
    }
} 

export default withRouter(CustomerSliderLayer)