
import React, {Component} from 'react'
import { Icon,Breadcrumb, Segment, Header,Dimmer,Loader, Form, Checkbox, Grid, Select} from 'semantic-ui-react';
import { ResponsiveBar } from '@nivo/bar'
import client from '../../../shared/feathers';
import {getDaysByWeekday} from '../../../shared/helper';

let weekDays=[
    {key:1, value:1, text: "Montag"},
    {key:2, value:2, text: "Dienstag"},
    {key:3, value:3, text: "Mittwoch"},
    {key:4, value:4, text: "Donnerstag"},
    {key:5, value:5, text: "Freitag"},
    {key:6, value:6, text: "Samstag"},
    {key:7, value:7, text: "Sonntag"},
]

class PeakHours extends Component{

    state={
        day:1,
        loading:true,
        empty:false,
        data:[
            {id:"8:00","Kunden":2000},
            {id:"9:00","Kunden":800},
            {id:"10:00","Kunden":800},
            {id:"11:00","Kunden":800},
            {id:"12:00","Kunden":800},
            {id:"13:00","Kunden":800},
            {id:"14:00","Kunden":800},
            {id:"15:00","Kunden":800},
            {id:"16:00","Kunden":800},
            {id:"17:00","Kunden":800}
        ]
    }

    async componentDidMount(){
        await this.update()
    }
    async update(){
        this.setState({loading:true})
        let days = getDaysByWeekday(this.state.day,4)
        let result = await client.service("reports_x_accounting").find({
            query: {
                $limit:1000,
                $and: [
                    {
                        register_id:{
                            $in:[
                                ...this.props.registers.map((reg)=>reg.id)
                            ]
                        }
                    },
                    {$or:[...days.map((value)=>{return {day: value.format("YYYY-MM-DD")}})]}
                    
                ]
            }
        })
        if(result.total == 0 ){
            this.setState({loading:false,empty:true})    
            return 0
        }else{
            let dataObj = {}
            result.data.forEach((elm)=>{
                dataObj[elm.hour+":00"] = dataObj[elm.hour+":00"] ? elm.customers+dataObj[elm.hour+":00"]:elm.customers
            })
            let data = []
            for (var key in dataObj) {
                if (!dataObj.hasOwnProperty(key)) continue;
                data.push({id:key,"Kunden":dataObj[key]})
            }
            this.setState({data,empty:false})
        }
        this.setState({loading:false})
    }
    changeDay(event,data){
        this.setState({day:data.value}, ()=>this.update())
    }

    render(){
        
        return (
            <Segment.Group>
                <Header attached='top'>
                    <Icon name="chart bar outline" style={{display:"initial"}}/> Stoßzeiten
                    <div style={{position:"absolute",right:0,top:0,marginRight:-1}}>
                        <Select defaultValue={this.state.day} onChange={this.changeDay.bind(this)} options={weekDays} style={{borderright:0,borderTop:0,borderBottom:0,border:"0 0 0 1px",borderRadius:0}} />
                    </div>
                </Header>

                {(this.state.empty && !this.state.loading ) && 
                    <Segment attached="bottom" placeholder style={{marginTop:0,borderTop:0}} >
                        <Header icon>
                            Nicht genug Daten vorrhanden
                        </Header>
                    </Segment>
                }
                {(this.state.loading ) && 
                    <Segment loading attached="bottom" placeholder style={{marginTop:0,borderTop:0}} ></Segment>
                }
                {(!this.state.empty && !this.state.loading)&& 
                    <Segment loading={this.state.loading} attached="bottom" style={{height:280}}>
                        <ResponsiveBar 
                            margin={{
                                "bottom": 25,
                            }}
                            keys={["Kunden"]}
                            enableLabel={false}
                            enableGridY={false}
                            borderRadius={5}
                            padding={0.3}
                            colors="#70a0f6" 
                            data={this.state.data} />
                    </Segment>
                }
            </Segment.Group>
        )
    }
} 

export default PeakHours