
import React, {Component} from 'react'
import {findDOMNode} from 'react-dom'
import {  Button, Message, Icon, Menu, Modal, Form,Tab} from 'semantic-ui-react';
import clientF from '../../../../shared/feathers';
import {withRouter} from 'react-router-dom'

let drivers=[
    {key:"mysql2",value:"mysql2",text:"MySQL"},
    {key:"pg",value:"pg",text:"Postgres"},
    {key:"oracle",value:"oracle",text:"Oracle"},
    {key:"mssql",value:"mssql",text:"SQL Server"}
]

class TenantAdd extends Component{
    state={
        saving:false,
        err:false,
        client: "mysql2"
    }
    
    async saveDetails(){
        this.setState({saving:true,err:false})
        let {name,client,host,port,username,password,database,connection} = this.state
        try{
            await clientF.service("tenants").create({company_id:this.props.company_id,active:false,name,client,host,port,username,password,database,connection})
            this.setState({saving:false})
            this.props.history.push("/Admin/Companies/"+this.props.company_id+"/databases")
        }catch(e){
            console.log(e)
            this.setState({saving:false,err:true})
        }
    }
    findForm(node){ this._form = node;}
    handleChange (e, { name, value }) {
        this.setState({ [name]: value })
    }
    render(){
        return (
            <Modal trigger={<Button color="blue" onClick={()=>{}}>{this.props.text}</Button>}>
                <Modal.Header>Datenbank hinzufügen</Modal.Header>
                <Modal.Content scrolling>
                    {this.state.err &&<Message error header='Fehler' content='Fehler beim Speichern. Bitte überprüfen Sie alle felder.' />}

                    <Form ref={this.findForm.bind(this)} onSubmit={this.saveDetails.bind(this)} loading={this.state.saving}>
                        <Form.Group widths='equal'>
                            <Form.Input required name="name" onChange={this.handleChange.bind(this)} fluid label='Name' placeholder='Name' />
                            <Form.Select value={this.state.client} name="client" onChange={this.handleChange.bind(this)} fluid label="Treiber" options={drivers} />
                        </Form.Group>
                        <Tab style={{marginBottom:10}} panes={[                        
                            {
                                menuItem: "Formular",
                                render: ()=><Tab.Pane>
                                    <Form.Group  widths='equal'>
                                        <Form.Input disabled name="host" onChange={this.handleChange.bind(this)}  fluid label='Host' placeholder='localhost' />
                                        <Form.Input disabled name="port" onChange={this.handleChange.bind(this)}  fluid label='Port' placeholder='3306' />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input disabled name="username" onChange={this.handleChange.bind(this)}  fluid label='Benutzername' placeholder='root' />
                                        <Form.Input disabled name="password" onChange={this.handleChange.bind(this)} fluid label='Passwort' type="password" />
                                    </Form.Group>
                                    <Form.Input disabled name="database" onChange={this.handleChange.bind(this)} fluid label='Datenbank' placeholder='kcontrol' />
                                </Tab.Pane>
                            },
                            {
                                menuItem: "Manuell",
                                render: ()=><Tab.Pane><Form.Input disabled name="connection" onChange={this.handleChange.bind(this)} fluid placeholder='mysql://root:root@kamphenkel.net:3306/example' /></Tab.Pane>
                            }
                        ]} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary loading={this.state.saving} onClick={()=>this._form.handleSubmit()}>Speichern</Button>
                </Modal.Actions>
            </Modal>
        )
    }
} 

export default withRouter(TenantAdd)