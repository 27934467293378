import React, {Component} from 'react'
import { Sidebar, List,Icon, Form, Select, Segment, Button, Menu } from 'semantic-ui-react'
import {  withRouter, Link } from "react-router-dom";
import client from '../../shared/feathers'

//()=>this.props.history.push("/Accounting")

class Settings extends Component{
    state={
        user:{company_id:0}
    }
    async componentDidMount(){
        this.setState({ user: await client.service("user").find() })
    }
    
    render(){
        return (
            <Sidebar
                style={{top:43,backgroundColor:"#fff",zIndex:10000}}
                animation='overlay'
                icon='labeled'
                direction="right"
                visible={this.props.active}
                width='wide'
            >
                <List size="large" style={{paddingLeft:20,paddingTop:20,paddingBottom:5}}>
                    <List.Item>
                        <List.Content>
                            <List.Header><Icon name="cogs" /> Einstellungen</List.Header>
                        </List.Content>
                    </List.Item>
                </List>
                <Menu style={{backgroundColor:"#f9f9f9",width:"100%"}} attached="top" vertical>
                    <Menu.Item onClick={()=>this.props.history.push("/Admin/Companies/"+this.state.user.company_id)} ><Icon name="building" /> Unternehmen</Menu.Item>
                    <Menu.Item onClick={()=>this.props.history.push("/Admin/Companies/"+this.state.user.company_id+"/databases")} ><Icon name="database" /> Datenbank</Menu.Item>
                </Menu>
            </Sidebar>
        )
    }
} 

export default withRouter(Settings)