import React, {Component} from 'react'
import { Grid,Segment,Breadcrumb, Header,List,Menu,Input} from 'semantic-ui-react';
import { withRouter, Route, Link } from "react-router-dom";
import Rooms from './Rooms';
import Room from './Room';
class Messages extends Component{
    state={
        active:"customerdisplay"
    }
    render(){
        return (    
            <Grid  >
                <Grid.Column width="3" style={{paddingRight:0}} >
                    <Segment.Group  style={{borderRadius:0, height:"calc(100vh - 43px)", overflow:"auto",backgroundColor:"#fff"}} >
                        <Segment attached="top">
                            <Breadcrumb>
                                <Breadcrumb.Section>Nachrichten</Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>Liste</Breadcrumb.Section>
                            </Breadcrumb>
                            <Header as='h1' style={{border:0,marginTop:10}}>Nachrichten</Header>
                        </Segment>
                        <Input style={{padding:10}}  icon="search" placeholder='Suche...' fluid transparent/>
                        <Rooms />
                    </Segment.Group>
                </Grid.Column>
                <Grid.Column width="13" style={{paddingLeft:0}} >
                <Route path="/Messages/:id" component={(props)=><Room {...props} />} />
                </Grid.Column>
            </Grid>
        )
    }
} 

export default withRouter(Messages)