
import React, {Component} from 'react'
import { Icon,Breadcrumb, Segment, Header,Dimmer,Loader, Form, Checkbox, Grid} from 'semantic-ui-react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet';
import client from '../../../shared/feathers';
import BranchRegisters from './Registers';
import PeakHours from '../../Reports/Charts/PeakHours';
import Accounting from '../../Reports/Accounting';
const markerIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
});



class BranchSingle extends Component{
    state={
        branch:null,
        loading:true,
        saving:false,
        registers:[],
        
    }
    
    async componentDidMount(){
        client.service("branches").get(this.props.match.params.id).then(branch=>this.setState({branch,loading:false})).catch(()=>this.setState({loading:false,branch:null}))
        client.service("registers").find({query:{$limit:10,branch_id:this.props.match.params.id}}).then(({data})=>this.setState({registers:data}))
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.match.params.id!=this.props.match.params.id){
            this.setState({loading:true, branch:null})
            this.updateService()
        }
    }
    updateService(){
        client.service("branches").get(this.props.match.params.id).then(branch=>this.setState({branch,loading:false})).catch(()=>this.setState({loading:false,branch:null}))
    }
    async updateField(field,value){
        await client.service("branches").patch(this.props.match.params.id,{[field]: value})
        this.updateService()
    }
    async saveAccountDetails(){
        this.setState({saving:true})
        let changed = {}
        let {address,address_extra,postcode,city,phone, lat,lng} = this.state
        try{
            await client.service("branches").patch(this.state.branch.id,{address,address_extra,postcode,city,phone,geo:(lat&&lng)?JSON.stringify([lat,lng]):undefined})
            this.setState({saving:false,password:undefined})
            this.updateService()
        }catch(e){
            this.setState({saving:false,password:undefined})
            this.updateService()
        }
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    render(){
        return (<div style={{marginRight:0,marginLeft:0}} >
                {(!this.state.loading && this.state.branch != null) && <div>
                    <Segment clearing attached="bottom" style={{borderRadius:0,marginRight:0,marginLeft:0,marginBottom:0,borderLeft:0}} >
                        <Breadcrumb style={{width:"100%"}}>
                            <Breadcrumb.Section>Infrastruktur</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section >Filialen</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active >#{this.state.branch.realid}</Breadcrumb.Section>
                        </Breadcrumb>
                        <Header as='h1' style={{border:0,marginTop:10}}>
                            {this.state.branch.name} 
                            <Header.Subheader>{this.state.branch.email}</Header.Subheader>
                        </Header>
                    </Segment>
                    <Grid style={{padding:"1rem"}}>
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Segment.Group>
                                    <Header attached="top"><Icon name="address card" /> Adresse</Header>
                                    <Segment attached="bottom">
                                        <Form onSubmit={this.saveAccountDetails.bind(this)} loading={this.state.saving}>
                                            <Form.Input name="address" onChange={this.handleChange} fluid defaultValue={this.state.branch.address} label='Adresse' />
                                            <Form.Input name="address_extra" onChange={this.handleChange} fluid defaultValue={this.state.branch.address_extra}  />
                                            <Form.Group widths='equal'>
                                                <Form.Input width="3" type="number" name="postcode" onChange={this.handleChange} defaultValue={this.state.branch.postcode} fluid label='PLZ'  />
                                                <Form.Input name="city" onChange={this.handleChange} defaultValue={this.state.branch.city} fluid label='Stadt'  />
                                            </Form.Group>
                                            <Form.Input name="phone" onChange={this.handleChange} fluid defaultValue={this.state.branch.phone} label='Telefon'  />
                                            <Form.Group widths='equal'>
                                                <Form.Input type="string" name="lat" onChange={this.handleChange} defaultValue={this.state.branch.geo?this.state.branch.geo[0]:undefined} fluid label='Latitude'  />
                                                <Form.Input type="string" name="lng" onChange={this.handleChange} defaultValue={this.state.branch.geo?this.state.branch.geo[1]:undefined} fluid label='Longitude'  />
                                            </Form.Group>
                                            <Form.Button content='Speichern' />
                                        </Form>
                                    </Segment>
                                </Segment.Group>
                            </Grid.Column>
                            {this.state.branch.geo && <Grid.Column>
                                <Segment.Group>
                                    <Header attached="top"><Icon name="map" /> Karte</Header>
                                    <Segment attached="bottom" style={{height:417, overflow:"hidden",width:"calc(100% + 2px)", padding:0,position:"relative"}}>
                                        <Map center={this.state.branch.geo} zoom={13} controls style={{width:"100%",height:"100%"}}>
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors" />
                                            <Marker position={this.state.branch.geo} icon={markerIcon} />
                                        </Map>
                                    </Segment>
                                </Segment.Group>
                            </Grid.Column>}
                        </Grid.Row>
                        {this.state.registers.length > 0 &&
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <PeakHours registers={this.state.registers} />
                            </Grid.Column>
                            <Grid.Column>
                                <Accounting headerSmall={<span><Icon name="tv" /> Umsätze</span>} query={{$or:this.state.registers.map(elm=>{return {register_id:elm.id}})}} segmentProps={{attached:true, style:{padding:0}}} tableProps={{style:{borderRadius:0,border:0}, size:"small",celled:true}} />
                                
                            </Grid.Column>
                        </Grid.Row>
                        }
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Segment.Group>
                                    <Header attached="top"><Icon name="tv" /> Kassen</Header>
                                    <BranchRegisters branch_id={this.state.branch.id} />
                                </Segment.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>}
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {(!this.state.loading && this.state.branch==null) && <Header style={{width:"100%"}} icon>
                    <Icon name='search' />
                    Die gesuchte Ressource wurde nicht gefunden.
                </Header>}
            </div>
        )
    }
} 

export default BranchSingle