import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import * as RxJS from 'rxjs';
import reactive from 'feathers-reactive'

const socket = io('https://srv2.k-control.net');
const client = feathers();

client.configure(feathers.socketio(socket));
client.configure(reactive({idField:"id"}));
client.configure(feathers.authentication({
  storage: window.localStorage
}));

export default client

