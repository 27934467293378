
import client from '../../../shared/feathers';
let RegisterBranches = {}
const collabsBranches= async (data,combine)=>{
    let preResult ={}
    let curBranch=0
    let overall={
        brutto:data.data.reduce((acc,val)=>acc+val.brutto ,0),
        netto:data.data.reduce((acc,val)=>acc+val.netto ,0),
        tax:data.data.reduce((acc,val)=>acc+val.tax ,0),
        manuel_value:data.data.reduce((acc,val)=>acc+val.manuel_value ,0),
        customers:data.data.reduce((acc,val)=>acc+val.customers ,0),
        corrections:data.data.reduce((acc,val)=>acc+val.corrections ,0),
        corrections_value:data.data.reduce((acc,val)=>acc+val.corrections_value ,0),
        average_per_customer:data.data.reduce((acc,val)=>acc+val.average_per_customer ,0),
        customers_per_table:data.data.reduce((acc,val)=>acc+val.customers_per_table ,0),
        average_per_table:data.data.reduce((acc,val)=>acc+val.average_per_table ,0),
        average_off_table:data.data.reduce((acc,val)=>acc+val.average_off_table ,0),
        customers_off_table:data.data.reduce((acc,val)=>acc+val.customers_off_table ,0),
        value_off_table:data.data.reduce((acc,val)=>acc+val.value_off_table ,0),
        value_on_table:data.data.reduce((acc,val)=>acc+val.value_on_table ,0),
        cancellations:data.data.reduce((acc,val)=>acc+val.cancellations ,0),
        cancellation_value:data.data.reduce((acc,val)=>acc+val.cancellation_value ,0),
    }
    for (let entry of data.data){
        if(!entry.register_id){continue}
        if(entry.register_id in RegisterBranches){
            curBranch= RegisterBranches[entry.register_id]
        }else{
            curBranch = (await client.service("registers").get(entry.register_id)).branch_id
            RegisterBranches[entry.register_id]=curBranch
        }
        if(combine){
            if(curBranch+"_2019-01-01T23:00:00.000Z" in preResult){
                preResult[curBranch+"_2019-01-01T23:00:00.000Z"] = [entry, ...preResult[curBranch+"_2019-01-01T23:00:00.000Z"]]
            }else{
                preResult[curBranch+"_2019-01-01T23:00:00.000Z"] = [entry]
            }
        }else{
            if(curBranch+"_"+entry.day in preResult){
                preResult[curBranch+"_"+entry.day] = [entry, ...preResult[curBranch+"_"+entry.day]]
            }else{
                preResult[curBranch+"_"+entry.day] = [entry]
            }
        }
    }
    return {overall, data:{
        total:data.total,
        limit:data.limit,
        data: Object.keys(preResult).map((key)=>{
            let meta = key.split("_")
            return {
                id:key,
                branch_id:meta[0],
                corrections: preResult[key].reduce((acc,val)=>acc+val.corrections,0),
                corrections_value: preResult[key].reduce((acc,val)=>acc+val.corrections_value,0),
                cancellations: preResult[key].reduce((acc,val)=>acc+val.cancellations,0),
                cancellation_value: preResult[key].reduce((acc,val)=>acc+val.cancellation_value,0),
                netto: preResult[key].reduce((acc,val)=>acc+val.netto,0),
                brutto: preResult[key].reduce((acc,val)=>acc+val.brutto,0),
                value_off_table: preResult[key].reduce((acc,val)=>acc+val.value_off_table,0),
                value_on_table: preResult[key].reduce((acc,val)=>acc+val.value_on_table,0),
                average_per_customer: preResult[key].reduce((acc,val)=>acc+val.average_per_customer,0) / preResult[key].length,
                day:meta[1],
                children: preResult[key]
            }
        }) 
    }}
}
export {collabsBranches}