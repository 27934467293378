import React, {Component} from 'react'
import { Breadcrumb, Segment, Header,Menu, Icon, Input} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import OperatorsList from './list';
//import UserAdd from './add';


class Operators extends Component{
    state={
        search:""
    }
    filterSearch(entry){
        if(this.state.search!=""){
            if((entry.name).includes(this.state.search)){ return true }
            if(entry.description){if(entry.description.includes(this.state.search)){ return true }}
            if(entry.waregroup_id){if(entry.waregroup_id ==this.state.search){ return true }}
            return false
        }else{
            return true
        }
    }
    render(){
        return (
            <div style={{marginRight:0,marginLeft:0}} >
                <Segment attached="bottom" style={{borderRadius:0,marginRight:0,marginLeft:0,marginBottom:0,borderLeft:0}} >
                    <Breadcrumb>
                        <Breadcrumb.Section>Infrastruktur</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active>Bediener</Breadcrumb.Section>
                    </Breadcrumb>
                    <Header as='h1' style={{border:0,marginTop:10}}>Bediener</Header>
                </Segment>
                <OperatorsList searchOn search={this.filterSearch.bind(this)} />
            </div>
        )
    }
} 

export default Operators