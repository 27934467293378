import React, {Component} from 'react'
import { Menu,List, Segment, Grid, Statistic, Header, Icon, SegmentGroup, Button } from 'semantic-ui-react';
import client from '../../../shared/feathers';
import {withRouter,Link} from 'react-router-dom'

class Stats extends Component{
    state={
        branches:[],
        waregroups:[],
        discounts:[],
        users:[],
        registers:[],
        operators:[],
        modifier: [],
        products: [],
        countOperator: 0,
        countWaregroups: 0,
        countProducts: 0,
        loadingProducts: true,
        loadingOperators: true,
        loadingRegisters: true,
        loadingModifier: true,
        loadingWaregroups: true,
        loadingBranches: true,
        loadingDiscounts: true,
        loadingUsers: true
    }
    componentDidMount(){
        this.reactiveProducts = client.service("products").watch().find().subscribe(({data,total})=>this.setState({products:data,loadingProducts:false,countProducts:total}))
        this.reactiveRegisters = client.service("registers").watch().find().subscribe(({data})=>this.setState({registers:data,loadingRegisters:false}))
        this.reactiveUsers = client.service("users").watch().find().subscribe(({data})=>this.setState({users:data,loadingUsers:false}))
        this.reactiveBranches = client.service("branches").watch().find().subscribe(({data})=>this.setState({branches:data,loadingBranches:false}))
        this.reactiveWaregroups = client.service("waregroups").watch().find().subscribe(({data,total})=>this.setState({waregroups:data,loadingWaregroups:false,countWaregroups:total}))
        this.reactiveDiscounts = client.service("discounts").watch().find().subscribe(({data})=>this.setState({discounts:data,loadingDiscounts:false}))
        this.reactiveModifier = client.service("modifiers").watch().find().subscribe(({data})=>this.setState({modifier:data,loadingModifier:false}))
        this.reactiveOperators = client.service("operators").watch().find().subscribe(({data,total})=>this.setState({operators:data,loadingOperators:false,countOperator:total}))
    }
    componentWillUnmount(){
        this.reactiveProducts.unsubscribe()
        this.reactiveBranches.unsubscribe()
        this.reactiveRegisters.unsubscribe()
        this.reactiveWaregroups.unsubscribe()
        this.reactiveOperators.unsubscribe()
        this.reactiveDiscounts.unsubscribe()
        this.reactiveModifier.unsubscribe()
        this.reactiveUsers.unsubscribe()
    }
    render(){
        return (
            <Grid style={{padding:20}}>
                <Grid.Row>
                    <Grid.Column>
                        <Segment>
                            <Statistic.Group size="tiny" widths='5'>
                                <Statistic>
                                    <Statistic.Value>{this.state.branches.length}</Statistic.Value>
                                    <Statistic.Label>Filialen</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Value>{this.state.registers.length}</Statistic.Value>
                                    <Statistic.Label>Kassen</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Value>{this.state.countOperator}</Statistic.Value>
                                    <Statistic.Label>Bediener</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Value>{this.state.countWaregroups}</Statistic.Value>
                                    <Statistic.Label>Warengruppen</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Value>{this.state.countProducts}</Statistic.Value>
                                    <Statistic.Label>Produkte</Statistic.Label>
                                </Statistic>
                            </Statistic.Group>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns="2">
                    <Grid.Column>
                        <SegmentGroup>
                            <Header as='h5' attached='top'><Icon name="users" /> Bediener</Header>
                            <Menu vertical attached fluid>
                                {this.state.operators.map(entry=>
                                    <Menu.Item onClick={()=>this.props.history.push("/Infrastructure/Operators/"+entry.id)} key={entry.id}>{entry.name} ({entry.realid})</Menu.Item>   
                                )}
                            </Menu>
                            <Button attached="bottom" onClick={()=>this.props.history.push("/Infrastructure/Operators")}>Mehr anzeigen</Button>
                        </SegmentGroup>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment.Group>
                            <Header as='h5' attached='top'><Icon name="food" /> Produkte</Header>
                            <Menu vertical fluid attached>
                                    {this.state.products.map(entry=>
                                        <Menu.Item>{entry.name} ({entry.realid})</Menu.Item>   
                                    )}
                            </Menu>
                            <Button attached="bottom" onClick={()=>this.props.history.push("/Infrastructure/Products/")}>Mehr anzeigen</Button>
                        </Segment.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
} 

export default withRouter(Stats)