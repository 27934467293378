import React, {Component} from 'react'
import {Segment, Breadcrumb, Header,Menu,Icon, Button} from 'semantic-ui-react'
import { withRouter, Route, Link } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class CustomerSliderLayout extends Component{

    render(){
        
        return (
            <Segment style={{marginBottom:0}} >
                
                <Slider variableWidth adaptiveHeight infinite={false} >
                    {this.props.slides.map((elm)=>
                        <div>
                            <Segment key={elm.id} onClick={()=>this.props.history.push("/Controlling/Slider/"+elm.id)} style={{marginRight:20,cursor:"pointer",marginBottom:0,marginTop:0, width:150,height:100, minHeight:100, backgroundImage:"url("+elm.big+")",color:"#fff",textShadow:" 2px 2px 5px rgba(0,0,0,1)"}}>
                                Style1
                            </Segment>
                        </div>
                    )}
                    <div>
                        <Segment placeholder style={{marginBottom:0,marginTop:0, width:150,height:100, minHeight:100}}>
                            <Button icon><Icon name="add"/></Button>
                        </Segment>
                    </div>
                </Slider>
                
            </Segment>
        )
    }
} 

export default withRouter(CustomerSliderLayout)