import React, {Component} from 'react'
import {Segment, Breadcrumb, Header,Menu,Icon} from 'semantic-ui-react'
import { withRouter, Route, Link } from "react-router-dom";
import client from '../../../shared/feathers'
import CustomerSliderLayout from './Layout'
import CustomerSliderLayer from './Layer'

class CustomerSlider extends Component{

    state={loading:true,slides:[]}
    async componentDidMount(){
        client.service("customerwindow_medias").watch().find().subscribe(({data})=>this.setState({slides:data,loading:false}))
    }
    render(){
        
        return (
            <Segment basic loading={this.state.loading} style={{padding:0, margin:"-1px 0 0 0"}}>
                <CustomerSliderLayout slides={this.state.slides} />
                <Route path={`${this.props.match.path}/:id`} component={CustomerSliderLayer} />
            </Segment>
        )
    }
} 

export default withRouter(CustomerSlider)