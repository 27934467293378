
import React, {Component} from 'react'
import {findDOMNode} from 'react-dom'
import {  Button, Message, Icon, Menu, Modal, Form} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import {withRouter} from 'react-router-dom'

class CompanyAdd extends Component{
    state={
        currentUser:{isAdmin:false},
        saving:true,
        err:false,
        geo:[0,0],
        currency: "EUR"
    }
    
    async componentDidMount(){
        this.setState({currentUser:await client.service("user").find()})
        client.service("companies").watch().find().subscribe(companies=>{
            this.setState({saving:false,companies:companies.data.map(entry=>{return {key:entry.id,value:entry.id,text:entry.name}})})
        })
    }
    
    async saveAccountDetails(){
        this.setState({saving:true,err:false})
        let {name,phone,address,address_extra,postcode,city,currency,geo,isActive} = this.state
        try{
            let newClient = await client.service("companies").create({name,phone,address,address_extra,postcode,city,currency,geo:JSON.stringify(geo),isActive})
            this.setState({saving:false})
            this.props.history.push("/Admin/Companies/"+newClient.id)
        }catch(e){
            console.log(e)
            this.setState({saving:false,err:true})
        }
    }
    findForm(node){ this._form = node;}
    handleChange (e, { name, value }) {
        if(name=="lat"){
            this.setState({ geo:[this.state.geo[0],value] })    
        }
        if(name=="lng"){
            this.setState({ geo:[value,this.state.geo[1]] })    
        }else{
            this.setState({ [name]: value })
        }
    }
    render(){
        return (
            <Modal trigger={<Menu.Item color="blue" onClick={()=>{}}><Icon name="plus circle" /> Hinzufügen</Menu.Item>}>
                <Modal.Header>Unternehmen hinzufügen</Modal.Header>
                <Modal.Content scrolling>
                    {this.state.err &&<Message error header='Fehler' content='Fehler beim Speichern. Bitte überprüfen Sie alle felder.' />}
                    <Form ref={this.findForm.bind(this)} onSubmit={this.saveAccountDetails.bind(this)} loading={this.state.saving}>
                        <Form.Input required name="name" onChange={this.handleChange.bind(this)} fluid label='Name' placeholder='Name' />
                        <Form.Input name="phone" onChange={this.handleChange.bind(this)} fluid label='Telefonnummer' placeholder='0175 235282928' />
                        <Form.Input name="address" onChange={this.handleChange.bind(this)} fluid label='Adresse' placeholder='Musterweg 24' />
                        <Form.Input name="address_extra" onChange={this.handleChange.bind(this)} fluid label='Adresszusatz' placeholder='Hinterhof' />
                        <Form.Group widths='equal'>
                            <Form.Input name="postcode" onChange={this.handleChange.bind(this)}  fluid label='PLZ' placeholder='00000' />
                            <Form.Input name="city" onChange={this.handleChange.bind(this)}  fluid label='Stadt' placeholder='Musterstadt' />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input type="string" name="lat" onChange={this.handleChange.bind(this)} defaultValue={this.state.geo?this.state.geo[0]:undefined} fluid label='Latitude'  />
                            <Form.Input type="string" name="lng" onChange={this.handleChange.bind(this)} defaultValue={this.state.geo?this.state.geo[1]:undefined} fluid label='Longitude'  />
                        </Form.Group>
                        <Form.Select name="currency" onChange={this.handleChange.bind(this)} fluid label="Währung" defaultValue={this.state.currency} options={[{key:"EUR",value:"EUR",text:"Euro €"}]} />
                        <Form.Checkbox onChange={this.handleChange.bind(this)} name="isActive" label='Aktiviert' />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary loading={this.state.saving} onClick={()=>this._form.handleSubmit()}>Speichern</Button>
                </Modal.Actions>
            </Modal>
        )
    }
} 

export default withRouter(CompanyAdd)