import React, {Component} from 'react'
import { Modal, Dropdown, Button, Form, Message} from 'semantic-ui-react';
import { withRouter, Route, Link } from "react-router-dom";
import client from '../../shared/feathers';
import moment from 'moment';

class AddDirekt extends Component{
    state={
        open:false,
        name:"",
        type:"",
        sort:0,
        branches:[],
        users:[],
        openSelect:false,
        selectName:"",
        searchQuery:""
    }
    async componentDidMount(){
        const users = (await client.service("users").find()).data
        const branches = (await client.service("branches").find()).data
        this.setState({users,branches})
    }
    handleChange = (e, { name,value }) => this.setState({ [name]:value })
    async close(){
        this.setState({name:"",sort:0,open:false,searchQuery:""})
        const users = (await client.service("users").find()).data
        const branches = (await client.service("branches").find()).data
        this.setState({users,branches})
    }
    async onSearch(e, { searchQuery }) {
        const users = (await client.service("users").find({
            query:{
                $or:[
                    {firstname:{$ilike:"%"+searchQuery+"%"}},
                    {lastname:{$ilike:"%"+searchQuery+"%"}} 
                ]
            }
        })).data
        const branches = (await client.service("branches").find({query:{name:{$ilike:"%"+searchQuery+"%"}}})).data
        this.setState({users,branches,searchQuery})
    }
    selectEntity(id,type,selectName){
        this.setState({type,id,selectName,searchQuery:""})
    }
    async onSend(){
        let user=await client.service("user").find()
        const {id,type,message} = this.state
        let exsisting = await client.service("messages_rooms").find({query:{$or:[
            {$and:[
                {from: user.id,from_type:"user"},
                {to: id,to_type:type}
            ]},
            {$and:[
                {to: user.id,to_type:"user"},
                {from: id,from_type:type}
            ]}
        ]}})
        if(exsisting.total > 0){
            await client.service("messages").create({
                text:message,from:user.id,from_type:"user",message_room_id:exsisting.data[0].id,created_at:moment()
            })
            this.setState({open:false})
            this.props.history.push("/Messages/"+exsisting.data[0].id)
        }else{
            const newRoom = await client.service("messages_rooms").create({
                private:true,created_at:moment(),updated_at:moment(),
                name:"PRIVATE-"+user.firstname+"-"+user.lastname,
                to:id,to_type:type,
                from:user.id,from_type:"user"
            })
            this.setState({open:false})
            this.props.history.push("/Messages/"+newRoom.id)
        }
    }
    render(){
        return (
            <Modal onSubmit={this.onSend.bind(this)} as={Form} open={this.state.open} onOpen={()=>this.setState({open:true})} onClose={this.close.bind(this)} size="mini" trigger={this.props.trigger}>
                
                <Modal.Content  >
                    
                    <Form.Dropdown text={this.state.selectName?this.state.selectName:"Bitte auswählen"}
                    search
                    onSearchChange={this.onSearch.bind(this)}
                    searchQuery={this.state.searchQuery}
                    open={this.state.openSelect} onClick={()=>this.setState({openSelect:!this.state.openSelect})} 
                    onClose={()=>this.setState({openSelect:false})}
                    required search fluid selection label="An">
                        <Dropdown.Menu>
                            <Dropdown.Header icon='building' content='Filialen' />
                            {this.state.branches.map(el=><Dropdown.Item key={el.id} onClick={this.selectEntity.bind(this,el.id,"branch",el.name)}>{el.name}</Dropdown.Item>)}
                            <Dropdown.Header icon='users' content='Benutzer' />
                            {this.state.users.map(el=><Dropdown.Item key={el.id} onClick={this.selectEntity.bind(this,el.id,"user",el.firstname+" "+el.lastname)}>{el.firstname} {el.lastname}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Form.Dropdown>
                    <Form.TextArea onChange={this.handleChange.bind(this)} name="message" label="Nachricht" required />
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={this.close.bind(this)} as="a" size="small" negative>Schließen</Button>
                    <Button size="small" positive icon='checkmark' labelPosition='right' content='Senden' />
                </Modal.Actions>
            </Modal>
        )
    }
} 

export default withRouter(AddDirekt)