import React, {Component} from 'react'
import { Breadcrumb, Segment, Header,Menu, Table, Icon, Button} from 'semantic-ui-react';
import client from '../../../shared/feathers';


class UserAPIKeys extends Component{
    state={
        keys:[],
        loading: true
    }
    async componentDidMount(){
        await this.update()
    }
    async update(){
        this.setState({ keys: await client.service("users/"+this.props.user_id+"/api-keys").find() })
    }
    async create(){
        await client.service("users/"+this.props.user_id+"/api-keys").create({}); 
        await this.update()
    }
    async delete(id){
        await client.service("users/"+this.props.user_id+"/api-keys").remove(id); 
        await this.update()
    }
    render(){
        return (
            <div style={{position:"relative"}}>
                <Header attached="top">
                    <Icon name="key" /> APIKEYS
                </Header>

                {this.state.keys.length == 0 && 
                    <Segment attached="bottom" placeholder style={{marginTop:0,borderTop:0}} >
                        <Header icon>
                            <Icon name='key' />
                            Es wurden noch keine Keys generiert
                        </Header>
                        <Button onClick={this.create.bind(this)} primary>Jetzt erstellen</Button>
                    </Segment>
                }
                {this.state.keys.length > 0 && 
                <Table compact attatched="bottom" style={{width: "calc(100% + 2px)",margin:"0 -1px",borderTop:0,borderRadius:"0 0 .28571429rem .28571429rem"}}>
                    <Table.Body>
                        {this.state.keys.map(entry=>
                            <Table.Row key={entry.id}>
                                <Table.Cell>{entry.id}</Table.Cell>
                                <Table.Cell textAlign="right">
                                    <Button size="mini" onClick={()=>this.delete(entry.id)} color="red" icon><Icon name='trash' /></Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>}
                <Button onClick={this.create.bind(this)} size="small" style={{position:"absolute",right:5,top:5}}><Icon name='plus circle' /> Erstellen</Button>
            </div>
        )
    }
} 

export default UserAPIKeys