import React, { Component } from 'react';
import { Breadcrumb, Loader } from 'semantic-ui-react';
import client from '../../../../shared/feathers';
import {  withRouter, Link } from "react-router-dom";
let allreadyFound = {

}

class TaxCompact extends Component{
    state={
        loading: true,
        tax: null
    }
    async componentDidMount(){
        if(!allreadyFound[this.props.id]){
            allreadyFound[this.props.id] = await client.service("realid-taxes").get(this.props.id)
        }
        this.setState({loading:false,tax:allreadyFound[this.props.id]})
    }

    render(){
        return (
            <div>
                {this.state.loading && <Loader active inline='centered' size="mini" />}
                {!this.state.loading && <Breadcrumb size='small'>
                    <Breadcrumb.Section >{this.state.tax.name}</Breadcrumb.Section>
                    
                </Breadcrumb>}
            </div>
        )
    }
} 

export default TaxCompact