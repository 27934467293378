
import React, {Component} from 'react'
import { Icon,Breadcrumb, Segment, Header,Dimmer,Loader, Form, Checkbox, Grid, Table, TableCell} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import TaxCompact from '../compact/Name/Tax'
import WaregroupCompact from '../compact/Name/Waregroup'
import Currency from 'react-currency-formatter';

class ProductSingle extends Component{
    state={
        product:null,
        loading:true,
        saving:false,
    }
    
    async componentDidMount(){
        client.service("products").get(this.props.match.params.id).then(product=>this.setState({product,loading:false})).catch(()=>this.setState({loading:false,product:null}))
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.match.params.id!=this.props.match.params.id){
            this.setState({loading:true, product:null})
            this.updateService()
        }

    }
    updateService(){
        client.service("products").get(this.props.match.params.id).then(product=>this.setState({product,loading:false})).catch(()=>this.setState({loading:false,product:null}))
    }
    render(){
        return (<div style={{marginRight:0,marginLeft:0}} >
                {(!this.state.loading && this.state.product != null) && <div>
                    <Segment clearing attached="bottom" style={{borderRadius:0,marginRight:0,marginLeft:0,marginBottom:0,borderLeft:0}} >
                        <Breadcrumb style={{width:"100%"}}>
                            <Breadcrumb.Section>Infrastruktur</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section >Produkte</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active >#{this.state.product.realid}</Breadcrumb.Section>
                        </Breadcrumb>
                        <Header as='h1' style={{border:0,marginTop:10}}>{this.state.product.name}</Header>
                    </Segment>
                    <Grid style={{padding:"1rem"}} columns="2">
                        <Grid.Column>
                            <Header attached="top"><Icon name="info" /> Informationen</Header>
                            <Table definition attached="bottom" style={{width: "calc(100% + 3px)",margin:"0 0 0 -1px"}}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Artikelnummer</Table.Cell>
                                        <Table.Cell>{this.state.product.realid}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Name</Table.Cell>
                                        <Table.Cell>{this.state.product.name}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Beschreibung</Table.Cell>
                                        <Table.Cell>{this.state.product.description}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Preis</Table.Cell>
                                        <Table.Cell><Currency quantity={this.state.product.price} currency="EUR" /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Warengruppe</Table.Cell>
                                        <Table.Cell><WaregroupCompact id={this.state.product.waregroup_id} /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Steuergruppe</Table.Cell>
                                        <Table.Cell><TaxCompact id={this.state.product.tax_id} /></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid>
                </div>}
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {(!this.state.loading && this.state.product==null) && <Header style={{width:"100%"}} icon>
                    <Icon name='search' />
                    Die gesuchte Ressource wurde nicht gefunden.
                </Header>}
            </div>
        )
    }
} 

export default ProductSingle