import React, { Component } from 'react';
import { Table, Header, Breadcrumb, Segment,Menu,Checkbox,Icon,Pagination } from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import Moment from 'react-moment';
import client from '../../../shared/feathers';
import BranchCompact from '../../Infrastructure/compact/Name/Branch';
import { BrowserRouter as Router, withRouter, Link } from "react-router-dom";
import MenuReports from '../Menu'
import {DatesRangeInput} from 'semantic-ui-calendar-react';
import moment from 'moment'
import 'moment/locale/de';
import ReceiptSingle from './single'

const InvoiceTypes = ["Coupon","EndRegistration","Angebot","Rechnung","GuestCheck","GuestCheckOrder","GuestcheckTransaction","HGReceiptCopy","Info","Invoice","InvoiceCopy","InvoiceProforma","KitchenVideoOrder","Legitimation","ManualInvoice","MenuSelection","Order","PayFinal","PayTemp","PrintByRound","PrintByRoundFinal","StartRegistration","StdReceipt","TempInvoice","VoidGuestCheck","VoidInvoice","VoidReceipt"]

class Receipts extends Component{
    state={
        data: {total:0,limit:200,data:[]},
        page:1,
        date:"",
        query:{},
        loading:true,
    }
    componentDidMount(){
        this.update()
    }
    update(){
        this.setState({loading:true})
        if(this.reactive){this.reactive.unsubscribe()}
        this.reactive = client.service("receipts")
        .watch().find({query: {$sort:{id:-1},...this.state.query,...this.props.query}}).subscribe(data=>this.setState({data,loading:false}))
    }
    componentDidUpdate(prevProps, prevState){
        let fullQuery = {}
        let changed = false
        if(JSON.stringify(this.state.query) != JSON.stringify(prevState.query) ){
            this.update()
        }
        if(prevState.date != this.state.date){
            if(this.state.date!=''){
                let dates = this.state.date.split(" - ")
                if(dates[1]!=""){
                    changed=true
                    fullQuery["$and"]=[
                        {receipt_time:{$lte:dates[1]}},
                        {receipt_time:{$gte:dates[0]}}
                    ]
                }else{
                    changed=true
                    fullQuery["$and"]=[
                        {receipt_time:{$gte:moment(dates[0], "YYYY-MM-DD").startOf("day")}},
                        {receipt_time:{$lte:moment(dates[0], "YYYY-MM-DD").endOf("day")}}
                    ]
                }
            }else{
                changed=true
                fullQuery = {}
            }
        }
        if(this.state.page != prevState.page){
            changed=true
            fullQuery["$skip"] = (this.state.page * this.state.data.limit)-this.state.data.limit
        }
        if(changed){this.setState({query:fullQuery})}
    }
    componentWillUnmount(){
        this.reactive.unsubscribe()
    }

    changePage(event,data){
        this.setState({loading:true,page:data.activePage},()=>this.update())
    }
    render(){
        let fullscreenStyle={}
        if(this.props.fullscreen){
            fullscreenStyle={position:"absolute",overflowX:"hidden",top:140,height:window.innerHeight-183,width:"100%"}
        }
        return (
            <Segment.Group placeholder={this.state.data==null} loading={this.state.data==null} {...this.props.segmentProps}>
                {this.props.headerSmall && 
                    <Header as='h5' attached='top'>{this.props.headerSmall}</Header>
                }
                {this.props.header && 
                    <Segment attached="top" style={{borderRadius:0}} >
                        
                        <Breadcrumb>
                            <Breadcrumb.Section>Berichte</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active>Rechnungen</Breadcrumb.Section>
                        </Breadcrumb>
                        <Header as='h1' style={{border:0,marginTop:10}}>Rechnungen</Header>
                    </Segment>
                }
                {this.props.header && 
                    <MenuReports 
                        after={
                        <Menu.Menu position='right'>
                            <Menu.Item>
                                <DatesRangeInput 
                                    placeholder="Von - Bis"
                                    style={{width:250}}
                                    dateFormat={"YYYY-MM-DD"}
                                    transparent 
                                    localization='de' 
                                    value={this.state.date}
                                    onChange={(event, {name, value})=>this.setState({date:value})}
                                    clearable 
                                    clearIcon={<Icon name="remove" color="red" />} name='fromto' />
                            </Menu.Item>
                        </Menu.Menu>
                        } 
                        menuPorps={{style:{marginBottom:0}}} />                    
                        
                }
                <Segment.Group style={{margin:0,marginTop:-1,...fullscreenStyle}} >
                {(this.state.data != null) &&
                    <Table attached size="small" celled {...this.props.tableProps}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>RNR.</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Type</Table.HeaderCell>
                                <Table.HeaderCell >Filiale</Table.HeaderCell>
                                <Table.HeaderCell>Bediener</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Produkte</Table.HeaderCell>
                                <Table.HeaderCell>Wert</Table.HeaderCell>
                                <Table.HeaderCell >Datum</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Dauer <small>in Sek.</small></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.data.data.map(entry => (
                                <Table.Row key={entry.id} >
                                    <Table.Cell><Link to={"/Reports/Receipts/"+entry.id} >{entry.realid}</Link></Table.Cell>
                                    <Table.Cell>{InvoiceTypes[entry.type-1]}</Table.Cell>
                                    <Table.Cell><BranchCompact register register_id={entry.register_id}/></Table.Cell>
                                    <Table.Cell>{entry.operator_name} ({entry.operator_no})</Table.Cell>
                                    <Table.Cell>{entry.products_count}</Table.Cell>
                                    <Table.Cell><Currency quantity={entry.value} currency="EUR" /></Table.Cell>
                                    <Table.Cell><Moment date={entry.receipt_time} format="DD.MM.YYYY HH:mm" /></Table.Cell>
                                    <Table.Cell><Moment locale="de" duration={entry.receipt_time} date={entry.end_time ? entry.end_time:moment()} /></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                }

                {this.props.pagination && 
                    <Pagination 
                    onPageChange={this.changePage.bind(this)}
                    attached="bottom" 
                    boundaryRange={2} 
                    siblingRange={2} 
                    fluid 
                    defaultActivePage={this.state.page} 
                    totalPages={Math.ceil(this.state.data.total / this.state.data.limit)} />
                }
                {this.props.match.params.id && <ReceiptSingle id={this.props.match.params.id} />}
                </Segment.Group>
                {this.props.groupChildren}
            </Segment.Group>
        )
    }
} 
export default withRouter(Receipts)