import React, {Component} from 'react'
import { Header,Label, Loader, Menu, Icon, Button } from 'semantic-ui-react';
import {  withRouter } from "react-router-dom";
import client from '../../shared/feathers';
import AddGroup from './addGroup';
import AddDirekt from './addDirekt';
import moment from 'moment';


class GetName extends Component{
    state={
        loading: true,
        name: ""
    }
    async componentDidMount(){
        let user=await client.service("user").find()
        let who;
        if (this.props.from == user.id && this.props.from_type=="user"){
            if(this.props.to_type == "user"){
                who = await client.service("users").get(this.props.to)
                this.setState({loading:false,name:who.firstname+" "+who.lastname})
            }else{
                who = await client.service("branches").get(this.props.to)
                this.setState({loading:false,name:who.name})
            }
        }else{
            if(this.props.from_type == "user"){
                who = await client.service("users").get(this.props.from)
                this.setState({loading:false,name:who.firstname+" "+who.lastname})
            }else{
                who = await client.service("branches").get(this.props.from)
                this.setState({loading:false,name:who.name})
            }
        }
    }
    render(){
        if(this.state.loading){
            return <Loader inline active size="tiny" />        
        }else{
            return this.state.name
        }
    }
} 

export default GetName