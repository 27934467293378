
import React, {Component} from 'react'
import { Icon,Breadcrumb, Segment, Header,Dimmer,Loader, Form, Checkbox, Grid} from 'semantic-ui-react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet';
import client from '../../../shared/feathers';
import PeakHours from '../../Reports/Charts/PeakHours';
import CompanyEdit from './edit';
import Tenant from './Tenant';
const markerIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
});



class CompanySingle extends Component{
    state={
        company:null,
        loading:true,
        saving:false,
        registers:[]
    }
    
    async componentDidMount(){
        client.service("companies").get(this.props.match.params.id).then(company=>this.setState({company,loading:false})).catch(()=>this.setState({loading:false,company:null}))
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.match.params.id!=this.props.match.params.id){
            this.setState({loading:true, company:null})
            this.updateService()
        }
    }
    updateService(){
        client.service("companies").get(this.props.match.params.id).then(company=>this.setState({company,loading:false})).catch(()=>this.setState({loading:false,company:null}))
    }


    render(){
        return (<div style={{marginRight:0,marginLeft:0}} >
                {(!this.state.loading && this.state.company != null) && <div>
                    <Segment clearing attached="bottom" style={{borderRadius:0,marginRight:0,marginLeft:0,marginBottom:0,borderLeft:0}} >
                        <Breadcrumb style={{width:"100%"}}>
                            <Breadcrumb.Section>Administration</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section >Unternehmen</Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active >{this.state.company.name}</Breadcrumb.Section>
                        </Breadcrumb>
                        <Header as='h1' style={{border:0,marginTop:10}}>
                            {this.state.company.name} 
                        </Header>
                    </Segment>
                    <Grid style={{padding:"1rem"}}>
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <CompanyEdit update={this.updateService.bind(this)} company={this.state.company} />
                            </Grid.Column>
                            <Grid.Column>
                                <Tenant company={this.state.company.id} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>}
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {(!this.state.loading && this.state.company==null) && <Header style={{width:"100%"}} icon>
                    <Icon name='search' />
                    Die gesuchte Ressource wurde nicht gefunden.
                </Header>}
            </div>
        )
    }
} 

export default CompanySingle