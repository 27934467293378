
import React, {Component} from 'react'
import { Icon, Segment, Header, Form} from 'semantic-ui-react';
import client from '../../../shared/feathers';

class CompanyEdit extends Component{
    state={
        saving: false,
        id:0,
        geo:[0,0]
    }
    componentDidMount(){
        this.setState({...this.props.company})
    }
    componentDidUpdate(prevProps,prevState){
        if(prevProps.company.id != this.props.company.id){
            this.setState({...this.props.company})
        }

    }
    
    handleChange (e, { name, value }) {
        if(name=="lat"){
            this.setState({ geo:[this.state.geo[0],value] })    
        }
        if(name=="lng"){
            this.setState({ geo:[value,this.state.geo[1]] })    
        }else{
            this.setState({ [name]: value })
        }
    }

    async saveDetails(){
        this.setState({saving:true})
        let {name,phone,address,address_extra,postcode,city,currency,geo} = this.state
        try{
            await client.service("companies").patch(this.state.id,{name,phone,address,address_extra,postcode,city,currency,geo:JSON.stringify(geo)})
            this.setState({saving:false,password:undefined})
            this.props.update()
        }catch(e){
            console.log(e)
            this.setState({saving:false,password:undefined})
            this.props.update()
        }
    }

    render(){
        return (
            <Segment.Group>
                <Header attached="top"><Icon name="address card" /> Informationen</Header>
                <Segment attached="bottom">
                    <Form onSubmit={this.saveDetails.bind(this)} loading={this.state.saving}>
                        <Form.Input defaultValue={this.state.name} required name="name" onChange={this.handleChange.bind(this)} fluid label='Name' placeholder='Name' />
                        <Form.Input defaultValue={this.state.phone} name="phone" onChange={this.handleChange.bind(this)} fluid label='Telefonnummer' placeholder='0175 235282928' />
                        <Form.Input defaultValue={this.state.address} name="address" onChange={this.handleChange.bind(this)} fluid label='Adresse' placeholder='Musterweg 24' />
                        <Form.Input defaultValue={this.state.address_extra} name="address_extra" onChange={this.handleChange.bind(this)} fluid label='Adresszusatz' placeholder='Hinterhof' />
                        <Form.Group widths='equal'>
                            <Form.Input defaultValue={this.state.postcode} name="postcode" onChange={this.handleChange.bind(this)}  fluid label='PLZ' placeholder='00000' />
                            <Form.Input defaultValue={this.state.city} name="city" onChange={this.handleChange.bind(this)}  fluid label='Stadt' placeholder='Musterstadt' />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input defaultValue={this.state.geo[0]} type="string" name="lat" onChange={this.handleChange.bind(this)} defaultValue={this.state.geo?this.state.geo[0]:undefined} fluid label='Latitude'  />
                            <Form.Input defaultValue={this.state.geo[1]} type="string" name="lng" onChange={this.handleChange.bind(this)} defaultValue={this.state.geo?this.state.geo[1]:undefined} fluid label='Longitude'  />
                        </Form.Group>
                        <Form.Select value={this.state.currency} name="currency" onChange={this.handleChange.bind(this)} fluid label="Währung" options={[{key:"EUR",value:"EUR",text:"Euro €"}]} />
                        <Form.Button content='Speichern' />
                    </Form>
                </Segment>
            </Segment.Group>
        )
    }
}

export default CompanyEdit