import React, { Component } from 'react';
import { Table, Header, List,Loader, Segment, Icon, Button,Sidebar } from 'semantic-ui-react';
import Currency from 'react-currency-formatter';
import Moment from 'react-moment';
import { withRouter } from "react-router-dom";
import client from '../../../shared/feathers';

const InvoiceTypes = ["Coupon","EndRegistration","Angebot","Rechnung","GuestCheck","GuestCheckOrder","GuestcheckTransaction","HGReceiptCopy","Info","Invoice","InvoiceCopy","InvoiceProforma","KitchenVideoOrder","Legitimation","ManualInvoice","MenuSelection","Order","PayFinal","PayTemp","PrintByRound","PrintByRoundFinal","StartRegistration","StdReceipt","TempInvoice","VoidGuestCheck","VoidInvoice","VoidReceipt"]


class ReceiptSingle extends Component{
    state={
        open:true,
        loading:true,
        data:null
    }
    componentDidMount(){this.update()}
    componentDidUpdate(prevProps){
        if(this.props.id != prevProps.id){
            this.setState({open:true,loading:true},this.update.bind(this))
        }
    }
    update(){
        this.reactive = client.service("receipts")
        .watch().get(this.props.id).subscribe(data=>this.setState({data,loading:false}))
    }
    componentWillUnmount(){
        if(this.reactive){this.reactive.unsubscribe()}
    }
    render(){
        return (
            <Sidebar
                as={Segment.Group}
                style={{top:183,bottom:0,backgroundColor:"#fff",zIndex:10000, overflow:"auto"}}
                animation='overlay'
                direction="right"
                icon='labeled'
                onHide={()=>this.setState({open:false})}
                visible={this.state.open}
                width='wide'
            >
            {this.state.loading &&
                <Loader active inline='centered' />
            }
            {!this.state.loading &&
            <div>
                <Segment clearing attached>
                    <Header floated="left" style={{marginBottom:0}}>
                        Rechnung #{this.state.data.realid}
                        <Header.Subheader><Moment format="YYYY.MM.DD HH:mm" locale="de">{this.state.data.receipt_time}</Moment></Header.Subheader>
                    </Header>
                    <Button onClick={()=>this.setState({open:false})} floated='right' icon ><Icon name="close" /></Button>
                </Segment>

                <Table style={{marginTop:0,borderBottom: "1px solid #d4d4d5"}} size="small" definition celled basic="very">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{paddingLeft:5}}>Bediener</Table.Cell>
                            <Table.Cell>{this.state.data.operator_name} ({this.state.data.operator_no})</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{paddingLeft:5}}>Type</Table.Cell>
                            <Table.Cell>{InvoiceTypes[this.state.data.type-1]}</Table.Cell>
                        </Table.Row>

                        { this.state.data.guestcheck_no >0 && <Table.Row>
                            <Table.Cell style={{paddingLeft:5}}>Tisch</Table.Cell>
                            <Table.Cell>{this.state.data.guestcheck_no}</Table.Cell>
                        </Table.Row>}
                        {this.state.data.extra.isBonVitoReceipt &&<Table.Row>
                            <Table.Cell style={{paddingLeft:5}}>BonVito</Table.Cell>
                            <Table.Cell>Aktiv</Table.Cell>
                        </Table.Row>}
                    </Table.Body>
                </Table>
                <List style={{marginTop:-5}} divided verticalAlign='middle' >
                {this.state.data.products && this.state.data.products.map((elm)=>
                    <List.Item key={elm.record_no} >
                        <List.Content style={{paddingRight:5}} floated='right'>
                            <Currency quantity={elm.price} currency="EUR" />
                        </List.Content>
                        <List.Content style={{paddingLeft:5}}>
                            <List.Header>{elm.name}</List.Header>
                            Menge x{elm.quantity}
                        </List.Content>
                    </List.Item>
                )}
                </List>
                <div style={{clear:"both"}} />
                <Table style={{marginTop:-8,borderBottom: "1px solid #d4d4d5",borderTop: "1px solid #d4d4d5"}} size="small" definition celled basic="very">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{paddingLeft:5}}>Gesamtsumme</Table.Cell>
                            <Table.Cell><Currency quantity={this.state.data.value} currency="EUR" /></Table.Cell>
                        </Table.Row>
                        {this.state.data.products && this.state.data.medias.map((elm)=>
                            <Table.Row id={elm.record_no}>
                                <Table.Cell style={{paddingLeft:5}}>{elm.type == 17 ? <small>Rückgeld - </small>:""}{elm.name}</Table.Cell>
                                <Table.Cell><Currency quantity={elm.type == 17 ? elm.amount*-1:elm.amount} currency="EUR" /></Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
            }
            
          </Sidebar>
        )
    }
}

export default withRouter(ReceiptSingle)