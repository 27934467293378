
import React, {Component} from 'react'
import {findDOMNode} from 'react-dom'
import {  Button, Message, Icon, Menu, Modal, Form} from 'semantic-ui-react';
import client from '../../../shared/feathers';
import {withRouter} from 'react-router-dom'

class UserAdd extends Component{
    state={
        currentUser:{isAdmin:false},
        saving:true,
        companies:[],
        err:false
    }
    
    async componentDidMount(){
        this.setState({currentUser:await client.service("user").find()})
        client.service("companies").watch().find().subscribe(companies=>{
            this.setState({saving:false,companies:companies.data.map(entry=>{return {key:entry.id,value:entry.id,text:entry.name}})})
        })
    }
    
    async saveAccountDetails(){
        this.setState({saving:true,err:false})
        let {firstname,lastname,company_id,email,position,language,password,active,isAdmin} = this.state
        try{
            let newClient = await client.service("users").create({firstname,lastname,company_id,email,position,language,password,active,isAdmin})
            this.setState({saving:false})
            this.props.history.push("/Infrastructure/Users/"+newClient.id)
        }catch(e){
            this.setState({saving:false,err:true})
        }
    }
    findForm(node){ this._form = node;}
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    render(){
        return (
            <Modal trigger={<Menu.Item color="blue" onClick={()=>{}}><Icon name="plus circle" /> Hinzufügen</Menu.Item>}>
                <Modal.Header>Benutzer hinzufügen</Modal.Header>
                <Modal.Content scrolling>
                    {this.state.err &&<Message error header='Fehler' content='Fehler beim Speichern. Bitte überprüfen Sie alle felder.' />}
                    <Form ref={this.findForm.bind(this)} onSubmit={this.saveAccountDetails.bind(this)} loading={this.state.saving}>
                        <Form.Group widths='equal'>
                            <Form.Input required name="firstname" onChange={this.handleChange}  fluid label='Vorname' placeholder='Vorname' />
                            <Form.Input required name="lastname" onChange={this.handleChange}  fluid label='Nachname' placeholder='Nachname' />
                        </Form.Group>
                        <Form.Input required name="email" onChange={this.handleChange} fluid  label='E-Mail' placeholder='Bsp. Buchhaltung' />
                        {this.state.currentUser.isAdmin==1 && (
                            <Form.Select name="company_id" onChange={this.handleChange} required fluid label="Unternehmen" placeholder='Unternehmen auswählen' options={this.state.companies} />
                        )}
                        <Form.Input name="position" onChange={this.handleChange} fluid  label='Position' placeholder='Bsp. Buchhaltung' />
                        <Form.Select required name="language" onChange={this.handleChange} fluid label="Sprache" placeholder='Sprache auswählen' options={[{key:"de",flag:"de",text:"Deutsch",value:"de"}]} />
                        <Form.Input required name="password" onChange={this.handleChange} fluid name="password" label='Passwort' type="password" />
                        <Form.Checkbox onChange={this.handleChange} name="active" label='Aktiviert' />
                        {this.state.currentUser.isAdmin == 1 &&<Form.Checkbox onChange={this.handleChange} name="isAdmin" label='Administrator' />}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary loading={this.state.saving} onClick={()=>this._form.handleSubmit()}>Speichern</Button>
                </Modal.Actions>
            </Modal>
        )
    }
} 

export default withRouter(UserAdd)