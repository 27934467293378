import React, {Component} from 'react'
import { Segment, Header, Table, Icon } from 'semantic-ui-react';
import client from '../../../../shared/feathers';
import RegistersMoreInformations from './more';


class BranchRegisters extends Component{
    state={
        registers:[],
        loading: true
    }
    async componentDidMount(){
        this.reactiveRegisters = client.service("registers").watch().find({query:{$limit:1000,branch_id:this.props.branch_id}}).subscribe(({data})=>this.setState({registers:data,loading:false}))
    }
    componentWillUnmount(){
        this.reactiveRegisters.unsubscribe()
    }
    async delete(id){
        await client.service("registers").remove(id); 
    }
    render(){
        return (
            <div style={{position:"relative"}}>
                {this.state.registers.length == 0 && 
                    <Segment attached="bottom" placeholder style={{marginTop:0,borderTop:0}} >
                        <Header icon>
                            <Icon name='tv' />
                            Es haben sich noch keine Kassen angmeldet.
                        </Header>
                    </Segment>
                }
                {this.state.registers.length > 0 && 
                <Table celled striped compact attatched="bottom" style={{width: "calc(100% + 2px)",margin:"0 -1px",borderTop:0,borderRadius:"0 0 .28571429rem .28571429rem"}}>
                    <Table.Body>
                        {this.state.registers.map(entry=>
                            <Table.Row key={entry.id}>
                                <Table.Cell collapsing>{entry.realid}</Table.Cell>
                                <Table.Cell>{entry.name}</Table.Cell>
                                <Table.Cell>S/N: {entry.serial}</Table.Cell>
                                <Table.Cell collapsing><RegistersMoreInformations data={entry} /></Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>}
            </div>
        )
    }
} 

export default BranchRegisters