import React, {Component} from 'react'
import { Sidebar, Menu, Icon, Segment } from 'semantic-ui-react'
import {  withRouter } from "react-router-dom";
import User from './User';
import Settings from './Settings';
import client from '../../shared/feathers';

class TopMenu extends Component{
    state = {
        user:false,
        settings:false,
        userData: {firstname:"", lastname:"", isAdmin:0}
    }
    async componentDidMount(){
        
        this.setState({userData: await client.service("user").find() }) 
    }
    render(){
        return (
            <div>
                <Menu attached="top" size='large' inverted style={{borderRadius:0,position:"fixed", top:0,height:43}}>
                    <Menu.Item ><b style={{color:"#db2728"}}>K</b>CONTROL</Menu.Item>
                    <Menu.Item onClick={()=>this.props.history.push("/Reports")} ><Icon name='dashboard' /> Dashboard</Menu.Item>
                    <Menu.Item onClick={()=>this.props.history.push("/Infrastructure")} ><Icon name='boxes' /> Infrastruktur</Menu.Item>
                    <Menu.Item onClick={()=>this.props.history.push("/Controlling")} ><Icon name='sliders horizontal' /> Controlling</Menu.Item>
                    <Menu.Item onClick={()=>this.props.history.push("/Messages")} ><Icon name='envelope' /> Nachrichten</Menu.Item>
                    {this.state.userData.isAdmin == 1 && <Menu.Item onClick={()=>this.props.history.push("/Charts")} ><Icon name='chart area' /> Auswertung</Menu.Item>}
                    {this.state.userData.isAdmin == 1 && <Menu.Item onClick={()=>this.props.history.push("/Admin")} ><Icon name='user secret' /> Admin</Menu.Item>}
                    <Menu.Menu position='right'>
                        {!this.state.user&& <Menu.Item onClick={()=>this.setState({settings:false,user:true})} ><Icon name='user' />{this.state.userData.firstname} {this.state.userData.lastname}</Menu.Item>}
                        {this.state.user&& <Menu.Item icon active color="red" onClick={()=>this.setState({settings:false,user:false})} ><Icon name='close' /></Menu.Item>}
                        {!this.state.settings&& <Menu.Item icon onClick={()=>this.setState({settings:true,user:false})} ><Icon name='setting' /></Menu.Item>}
                        {this.state.settings&& <Menu.Item icon active color="red" onClick={()=>this.setState({settings:false,user:false})} ><Icon name='close' /></Menu.Item>}
                    </Menu.Menu>
                </Menu>
                <Settings active={this.state.settings} />
                <User active={this.state.user} />
            </div>
        )
    }
} 

export default withRouter(TopMenu)