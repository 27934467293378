import React, {Component} from 'react'
import { Card, Icon,Grid,List } from 'semantic-ui-react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import client from '../shared/feathers'

import Reports from "./Reports";
import Infrastructure from "./Infrastructure";
import Menu from "./Menu";
import Controlling from './Controlling';
import Messages from './Messages';
import Admin from './Admin';
//lesivb@icloud.com

class Dashboard extends Component{
    render(){
        return (
            <Router>
                <div className="animated zoomIn" style={{ position:"fixed", top:0, left:0, width:"100vw", height:"100vh",backgroundColor:"#f0f2f5",paddingTop:43,  }}>
                    <Menu />
                    <Route path="/" exact component={Reports} />
                    <Route path="/Reports" component={Reports} />
                    <Route path="/Infrastructure" component={Infrastructure} />
                    <Route path="/Controlling" component={Controlling} />
                    <Route path="/Messages" component={Messages} />
                    <Route path="/Admin" component={Admin} />
                </div>

            </Router>
        )
    }
} 

export default Dashboard