import React, {Component} from 'react'
import { Header,Label, Loader, Menu, Icon, Button } from 'semantic-ui-react';
import {  withRouter } from "react-router-dom";
import client from '../../shared/feathers';
import AddGroup from './addGroup';
import AddDirekt from './addDirekt';
import moment from 'moment';
import GetName from './GetName';


class RoomList extends Component{
    state={
        public:[],
        private:[]
    }
    async componentDidMount(){
        let user=await client.service("user").find()
        this.reactivePublic = client.service("messages_rooms").watch().find({query:{private:false}}).subscribe(this.getPublic.bind(this))
        this.reactivePrivate = client.service("messages_rooms").watch().find({
            query:{
                $or:[
                    {from:user.id,from_type:"user"},
                    {to:user.id,to_type:"user"}
                ],
                private:true
            }
        }).subscribe(this.getPrivate.bind(this))

    }
    async getPublic({data}){
        let user=await client.service("user").find()
        let result=[]
        for (const room of data ) {
            let read = await client.service("messages_rooms_read").find({query:{user_id:user.id,room_id:room.id,user_type:"user"}}) 
            if( read.total > 0 ){
                if(moment(read.data[0].updated_at).isSameOrAfter(room.updated_at)){
                    result.push({
                        ...room,
                        new:false
                    })
                }else{
                    result.push({
                        ...room,
                        new:true
                    })
                }
            }else{
                result.push({
                    ...room,
                    new:true
                })
            }
            
            room.id            
        }
        this.setState({public:result})
    }
    async getPrivate({data}){
        let user=await client.service("user").find()
        let result=[]
        for (const room of data ) {
            let read = await client.service("messages_rooms_read").find({query:{user_id:user.id,room_id:room.id,user_type:"user"}}) 
            if( read.total > 0 ){
                if(moment(read.data[0].updated_at).isSameOrAfter(room.updated_at)){
                    result.push({
                        ...room,
                        new:false
                    })
                }else{
                    result.push({
                        ...room,
                        new:true
                    })
                }
            }else{
                result.push({
                    ...room,
                    new:true
                })
            }
            
            room.id            
        }
        this.setState({private:result})
    }
    componentWillUnmount(){
        this.reactivePublic.unsubscribe()
        this.reactivePrivate.unsubscribe()
    }
     render(){
        return (      
            <Menu vertical attached="bottom" fluid style={{borderLeft:0,borderRight:0}}>
                <Menu.Item>
                    <Menu.Header>
                        <Icon name="users" /> Gruppen
                        <AddGroup trigger={<Button icon="add" circular style={{marginLeft:5,padding:".3em .3em",fontSize:11}} basic />} />
                    </Menu.Header>
                    <Menu.Menu>
                        {this.state.public.map(el=><Menu.Item key={el.id} onClick={()=>this.props.history.push("/Messages/"+el.id)}>
                            {el.name}
                            {el.new && <Label  color='teal'>NEU</Label>}
                        </Menu.Item>)}
                    </Menu.Menu>
                </Menu.Item> 
                <Menu.Item>
                    <Menu.Header>
                        <Icon name="address card" /> Direkt Nachrichten
                        <AddDirekt trigger={<Button icon="add" circular style={{marginLeft:5,padding:".3em .3em",fontSize:11}} basic />} />
                    </Menu.Header>
                    <Menu.Menu>
                        {this.state.private.map(el=><Menu.Item key={el.id} onClick={()=>this.props.history.push("/Messages/"+el.id)}>
                            <GetName from={el.from} from_type={el.from_type} to={el.to} to_type={el.to_type} />
                            {el.new && <Label  color='teal'>NEU</Label>}
                        </Menu.Item>)}
                    </Menu.Menu>
                </Menu.Item>
            </Menu>
        )
    }
} 

export default withRouter(RoomList)