import React, {Component} from 'react'
import { Icon, Segment, Header, Accordion,Button} from 'semantic-ui-react';
import client from '../../../../shared/feathers';
import TenandEdit from './Edit';
import TenantAdd from './Add';

class Tenant extends Component{

    state={tenants:[],loading:false}

    componentDidMount(){
        this.tenants = client.service("tenants").watch().find({query:{company_id:this.props.company}}).subscribe(({data})=>this.setState({tenants:data}))
    }

    componentWillUnmount () {
        this.tenants.unsubscribe();
    }

    async activateTenant(id){
        this.setState({loading:true})
        for (const elm of this.state.tenants) {
            await client.service("tenants").patch(elm.id,{active:false})
        }
        await client.service("tenants").patch(id,{active:true})
        this.setState({loading:false})
    }
    render(){
        return (
            <div style={{position:"relative"}}>
                <Segment.Group  >
                    <Header attached="top"><Icon name="database" /> Datenbank</Header>
                    <Segment loading={this.state.loading} attached="bottom" style={{padding:0}}>
                        {this.state.tenants.length >0 &&<Accordion 
                            styled 
                            fluid 
                            style={{borderRadius:0}} 
                            panels={this.state.tenants.map((elm)=>{
                                return {
                                    key: "panel-"+elm.id,
                                    title: elm.name,
                                    content: 
                                        <Accordion.Content >
                                            <TenandEdit activate={this.activateTenant.bind(this)} tenant={elm} />
                                            {elm.active == 1 && <Segment inverted>
                                                {elm.error && <pre style={{color:"#f00"}} >{elm.error}</pre>}
                                                {!elm.error && <span >Datenbank erfolgreich verbunden</span>}
                                            </Segment>}
                                        </Accordion.Content>
                                    
                                }
                            }) } 
                        />}
                        {this.state.tenants.length == 0 && 
                            <Segment placeholder style={{border:0,borderRadius:0}}>                            
                                <Header icon>
                                    <Icon name='database' />
                                    Es wurden noch keine Datenbank angelegt.
                                </Header>
                                <TenantAdd company_id={this.props.company} text="Jetzt verbinden" />
                            </Segment>
                        }
                    </Segment>
                </Segment.Group>
                <div style={{position:"absolute",right:2,top:4}}><TenantAdd company_id={this.props.company} text={<span><Icon name="plus circle" /> Hinzufügen</span>} /></div>
            </div>
        )
    }
}

export default Tenant