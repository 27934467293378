import React, {Component} from 'react'
import { withRouter, Route  } from "react-router-dom";
import Dashboard from "./Dashboard";
import Accounting from './Accounting';
import Receipts from './Receipts';
import Operators from './Operators';

class Reports extends Component{
    render(){
        return (
            <div>
                <Route path="/Reports" exact component={Dashboard} />
                <Route path="/Reports/Accounting" exact component={()=><Accounting pagination header  />} />
                <Route path="/Reports/Receipts" exact component={()=><Receipts  pagination header query={{$limit:15}} fullscreen />}  />
                <Route path="/Reports/Receipts/:id" component={()=><Receipts  pagination header query={{$limit:15}} fullscreen />} />
                <Route path="/Reports/Operators" exact component={()=><Operators  pagination header fullscreen />}  />
            </div>
        )
    }
} 

export default withRouter(Reports)