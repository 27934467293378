import React, {Component} from 'react'
import { Menu, Segment,Breadcrumb,Header } from 'semantic-ui-react'
import { withRouter } from "react-router-dom";


class MenuReports extends Component{
    render(){
        return (
            <Menu attached="bottom" {...this.props.menuPorps}>
                {this.props.bevor}
                <Menu.Item name='dashboard' active={this.props.match.url == "/Reports"} onClick={()=>this.props.history.push("/Reports")}>Dashboard</Menu.Item>
                <Menu.Item name='accounting' active={this.props.match.url == "/Reports/Accounting"} onClick={()=>this.props.history.push("/Reports/Accounting")}>Finanzbericht</Menu.Item>
                <Menu.Item name='waregroup' active={this.props.match.url == "/Reports/Waregroups"} onClick={()=>this.props.history.push("/Reports/Waregroups")}>Warengruppenbericht</Menu.Item>
                <Menu.Item name='operators' active={this.props.match.url == "/Reports/Operators"} onClick={()=>this.props.history.push("/Reports/Operators")}>Bedienerbericht</Menu.Item>
                <Menu.Item name='receipts' active={this.props.match.url.includes("/Reports/Receipts")} onClick={()=>this.props.history.push("/Reports/Receipts")}>Rechnungen</Menu.Item>

                {this.props.after}
            </Menu>
        )
    }
} 

export default withRouter(MenuReports)