import React, { Component } from 'react';
import { Breadcrumb, Loader } from 'semantic-ui-react';
import client from '../../../../shared/feathers';
import {  withRouter, Link } from "react-router-dom";
let allreadyFound = {

}

class WaregroupCompact extends Component{
    state={
        loading: true,
        waregroup: null
    }
    async componentDidMount(){
        if(!allreadyFound[this.props.id]){
            allreadyFound[this.props.id] = await client.service("realid-waregroups").get(this.props.id)
        }
        this.setState({loading:false,waregroup:allreadyFound[this.props.id]})
    }

    render(){
        return (
            <div>
                {this.state.loading && <Loader active inline='centered' size="mini" />}
                {!this.state.loading && <Breadcrumb size='small'>
                    <Breadcrumb.Section link onClick={()=>this.props.history.push("/Infrastructure/Waregroups/"+this.state.waregroup.id)}>{this.state.waregroup.name} ({this.props.id})</Breadcrumb.Section>
                    
                </Breadcrumb>}
            </div>
        )
    }
} 

export default withRouter(WaregroupCompact)