
import React, {Component} from 'react'
import { Icon, Button, Tab, Form, Checkbox} from 'semantic-ui-react';
import clientF from '../../../../shared/feathers';

let drivers=[
    {key:"mysql2",value:"mysql2",text:"MySQL"},
    {key:"pg",value:"pg",text:"Postgres"},
    {key:"oracle",value:"oracle",text:"Oracle"},
    {key:"mssql",value:"mssql",text:"SQL Server"}
]
class TenandEdit extends Component{
    state={
        saving: false,
        id:0,
        manual: false
    }
    componentDidMount(){
        let manual=false
        if(this.props.connection){
            manual=true
        }
        this.setState({...this.props.tenant,manual})
    }
    componentDidUpdate(prevProps,prevState){
        if(prevProps.tenant.id != this.props.tenant.id){
            this.setState({...this.props.tenant})
        }
    }
    
    handleChange (e, { name, value }) {
        if (name=="kcontrol"){
            this.setState({ kcontrol:!this.state.kcontrol })
        }else{this.setState({ [name]: value })}
    }

    async saveDetails(){
        this.setState({saving:true})
        if(this.state.kcontrol){
            try{
                await clientF.service("tenants").patch(this.state.id,{name:this.state.name,kcontrol:true})
                this.setState({saving:false,password:undefined})
            }catch(e){
                this.setState({saving:false})
            }
            return true
        }

        let {name,client,host,port,username,password,database,connection} = this.state
        try{
            await clientF.service("tenants").patch(this.state.id,{name,client,host,port,username,password,database,connection})
            this.setState({saving:false,password:undefined})
        }catch(e){
            this.setState({saving:false})
        }
    }

    async delete(){
        await clientF.service("tenants").remove(this.state.id)
    }

    async activateKCONTROL(e){
        this.setState({saving:true})
        await clientF.service("tenants").patch(this.state.id,{kcontrol:true})
        this.setState({saving:false})
    }

    generatePanes(){
        let result = []
        result.push({
            menuItem: "KCONTROL",
            render: ()=>
            <Tab.Pane>
                {(!this.state.kcontrol ) && <Checkbox toggle name="kcontrol"  onChange={this.handleChange.bind(this)} /> }
                {this.state.kcontrol == 1 && <Button basic color="green" fluid icon>Wird verwendet <Icon name="check" /></Button>}
            </Tab.Pane>
        })
        if(!this.state.kcontrol && (this.state.connection=="" || !this.state.connection )){
            result.push({
                menuItem: "Formular",
                render: ()=><Tab.Pane>
                    <Form.Group widths='equal'>
                        <Form.Input defaultValue={this.state.host} name="host" onChange={this.handleChange.bind(this)}  fluid label='Host' placeholder='localhost' />
                        <Form.Input defaultValue={this.state.port} name="port" onChange={this.handleChange.bind(this)}  fluid label='Port' placeholder='3306' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input defaultValue={this.state.username} name="username" onChange={this.handleChange.bind(this)}  fluid label='Benutzername' placeholder='root' />
                        <Form.Input name="password" onChange={this.handleChange.bind(this)} fluid label='Passwort' type="password" />
                    </Form.Group>
                    <Form.Input defaultValue={this.state.database} name="database" onChange={this.handleChange.bind(this)} fluid label='Datenbank' placeholder='kcontrol' />
                </Tab.Pane>
            })
        }
        if(!this.state.kcontrol){
            result.push({
                menuItem: "Manuell",
                render: ()=><Tab.Pane><Form.Input defaultValue={this.state.connection} name="connection" onChange={this.handleChange.bind(this)} fluid placeholder='mysql://root:root@kamphenkel.net:3306/example' /></Tab.Pane>
            })
        }
        return result
    }

    render(){
        return (
            <Form onSubmit={this.saveDetails.bind(this)} loading={this.state.saving}>
                <Form.Group widths='equal'>
                    <Form.Input defaultValue={this.state.name} required name="name" onChange={this.handleChange.bind(this)} fluid label='Name' placeholder='Name' />
                    { !this.state.kcontrol && <Form.Select value={this.state.client} name="client" onChange={this.handleChange.bind(this)} fluid label="Treiber" options={drivers} /> }
                </Form.Group>
                <Tab style={{marginBottom:10}} panes={this.generatePanes()} />
                <span style={{float:"left"}}><Form.Button content='Speichern' /></span>
                {this.props.tenant.active==0 && <span style={{float:"left"}}><Button onClick={()=>this.props.activate(this.state.id)} content='Als Standart' /></span> }
                <span style={{float:"right"}}><Button onClick={this.delete.bind(this)} color='red' content='Löschen' /></span>
                <div style={{clear:"both"}} />
            </Form>
        )
    }
}

export default TenandEdit