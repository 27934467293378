
import React,{Component} from 'react'
import {render} from 'react-dom'

import Login from './Login'
import Dashboard from './Dashboard'
import client from './shared/feathers'

import 'semantic-ui-css/semantic.min.css'
import 'leaflet/dist/leaflet.css'

class App extends Component {
    state={
        login: null
    }
    componentDidMount(){
        client.authenticate().catch(() => this.setState({ login: false }));
        client.on('authenticated', () => this.setState({login:true}) )
        client.on('logout', () => this.setState({login:false}) )
    }
    render(){
        if(this.state.login == null){
            return <Login loading></Login>
        }else if(this.state.login == false){
            return <Login></Login>
        }
        return <Dashboard/>
    }

}
render(<App/>, document.querySelector('#app'))
